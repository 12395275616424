import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import googleStore from '../../assets/images/google.png';
import appleStore from '../../assets/images/appleStore.png';
import amazonStore from '../../assets/images/amazonStore.png';
import guidedTool from '../../assets/images/guidedTool.png';
import lancePhone from '../../assets/images/tablet_talks.jpg';
import backHead from '../../assets/images/background_header.png';

const Talks = ({ }) => {

    const [isVideoHidden, setVideoHide] = useState(true);
    const [isImgHidden, setImgHide] = useState(false);

    const toggleVideo = () => {
        setVideoHide(!isVideoHidden);
        setImgHide(!isImgHidden);
    }

    return (
        <div className="container">
            <div style={{background:"white"}}>
                <div className='talksDivFull'>
                    <h2 class="pt-3 pb-1" style={{paddingLeft:"30px", fontSize:"2.1vw"}}><b><b>TOP-LEVEL EVIDENCE, EXPERT OPINION, TAILORED TO PRIMARY CARE®</b></b></h2>
                    <div className='row' style={{padding:"0"}}>
                        <div style={{width:"100%", paddingBottom:"10px"}}>
                            <div className='talksDiv'>
                                <div className='desktop'>
                                    <div className='grid3' style={{paddingTop: "0px"}}>
                                        <div className='home-card card' style={{width:"95%"}}>
                                            <div>
                                                <h4 style={{textAlign:"center", padding:"10px"}}><b>Get the App</b></h4>
                                                <div className="store-link" style={{width:"70%", padding:"0px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                    <a href="https://apps.apple.com/us/app/waco-guide-psychopharmacology/id1503707099"><img src={appleStore} /></a>
                                                </div>
                                                <div className="store-link" style={{width:"70%", padding:"0px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                    <a href="https://play.google.com/store/apps/details?id=org.wacoguide.app"><img src={googleStore} /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='home-card card' style={{width:"95%"}}>
                                            <div>
                                                <h4 style={{textAlign:"center", padding:"10px"}}><b>Use the Website</b></h4>
                                                <div style={{width:"70%", padding:"30px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                <Link to="/"><img src={guidedTool} /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='home-card card' style={{width:"95%"}}>
                                            <div>
                                                <h4 style={{textAlign:"center", padding:"10px"}}><b>Buy the book</b></h4>
                                                <div style={{width:"70%", padding:"30px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                    <a><img src={amazonStore} /></a>
                                                    <h6 class="center">(coming soon)</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mobile'>
                                    <div className='row'>
                                        <div className='grid3' style={{paddingTop: "0px"}}>
                                            <div className='home-card card' style={{width:"95%"}}>
                                                <div>
                                                    <h4 style={{textAlign:"center", padding:"10px"}}><b>Get the App</b></h4>
                                                    <div className="store-link" style={{width:"70%", padding:"0px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                        <a href="https://apps.apple.com/us/app/waco-guide-psychopharmacology/id1503707099"><img src={appleStore} /></a>
                                                    </div>
                                                    <div className="store-link" style={{width:"70%", padding:"0px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                        <a href="https://play.google.com/store/apps/details?id=org.wacoguide.app"><img src={googleStore} /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='home-card card' style={{width:"95%"}}>
                                                <div>
                                                    <h4 style={{textAlign:"center", padding:"10px"}}><b>Use the Website</b></h4>
                                                    <div style={{width:"70%", padding:"30px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                    <Link to="/"><img src={guidedTool} /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='home-card card' style={{width:"95%"}}>
                                                <div>
                                                    <h4 style={{textAlign:"center", padding:"10px"}}><b>Buy the book</b></h4>
                                                    <div style={{width:"70%", padding:"30px 15px 15px 15px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                                                        <a><img src={amazonStore} /></a>
                                                        <h6 class="center">(coming soon)</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-2" style={{paddingBottom:"0px", marginTop:"50px"}}>
                    <div style={{paddingRight:"20px", paddingBottom:"0px"}}>
                        <h4 className='pt-3'>
                        Have you used the guide? Take our brief survey!
                        </h4>
                        <a href="https://baylor.qualtrics.com/jfe/form/SV_0St8iJtT5nnMRtH" className="btn btn-primary mt-3" style={{width:"50%", display:"inline-block", marginRight:"20px", color:"#212121", backgroundColor:"#F6D25C", borderColor:"#F6D25C"}}>Take the Survey</a>
                        
                        <h4 className='pt-5'>
                        Have any questions? Just let us know!
                        </h4>
                        <Link to="/contact-us" className="btn btn-primary mt-3" style={{width:"50%", display:"inline-block", color:"#212121", backgroundColor:"#F6D25C", borderColor:"#F6D25C", marginBottom:"50px"}}>Contact Us</Link>
                    </div>
                    <div>
                        <img src={lancePhone}/>
                    </div>
                </div>
                <div className="row">
                    </div>
            </div>
        </div>
    )
};

export default Talks