import React, { Component } from "react";
import { Link } from 'react-router-dom';

class 
Card extends Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div className="home-card card" style={{width:"95%"}}>
          <div className="grid-home" style={{margin:"20px 15px 20px 15px"}}>
              <div>
                  <img src={this.props.imageName} style={{padding:"10px", borderRadius:"100px"}}/>
              </div>
              <div>
                  <h5><b dangerouslySetInnerHTML={{ __html:this.props.title}}></b></h5>
                  <p style={{paddingBottom:"15px"}}>{this.props.description}</p>
                  <Link className='btn-yellow' style={{position:"absolute", bottom:"15px"}} to={this.props.link}>View Tools</Link>
              </div>
            </div>
        </div>
      )
    }
  }

  export default Card;