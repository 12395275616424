import React from 'react'
import './methodology.css';
import methodImg from '../../assets/images/methodology_chart.png';
import ryanSpeaking from '../../assets/images/topL.jpg';

const Methodology = ({ }) => {
    return (
        <div className="container">
   
            <div className="grid-2">
              <div>
                <h2 style={{marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%"}}>
                    <strong>METHODOLOGY</strong>
                </h2>
                <p class="" style={{whitespace:"pre-wrap"}}>
                The Waco Guide’s tools facilitate primary care clinicians’ pharmacologic treatment of mental and behavioral health disorders with:
                </p>
                <ul>
                  <li className='methodList'><b style={{color:"#008080"}}>Algorithms</b> providing systematic logic for decision-making that account for special populations with critical comorbid medical and demographic variations</li>
                  <li className='methodList'><b style={{color:"#008080"}}>Prescribing</b> information complete with dosing and monitoring recommendations for various pharmacologic treatment options</li>
                  <li className='methodList'><b style={{color:"#008080"}}>Evidence</b> for treatment choices in the form of strength of recommendation statements and seminal references</li>
                </ul>
                <div style={{textAlign:"center", position:"relative", paddingTop:"35px"}}>
                  <a style={{width:"80%", backgroundColor:"#F6D25C", borderColor:"#F6D25C", color:"#212121"}} href="../../assets/methodology_paper.pdf" className="btn btn-primary mt-3 centered" download>View the Methodology Paper</a>
                </div>
              </div>
                <div class="video-container">
                  <iframe style={{borderRadius:"8px"}} width="90%" src="https://www.youtube.com/embed/UFOPoaeIOK4" title="The Waco Guide - About" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
          <div>
            <div>
                <h2 style={{marginTop:"20px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%"}}>
                    <strong>CONTRIBUTORS AND FUNDING</strong>
                </h2>
                <p class="" style={{whitespace:"pre-wrap"}}>
                  The Waco Guide was developed by <a href="https://residency.wacofamilymedicine.org/" target="_blank"> Waco Family Medicine Residency</a> faculty in consultation with faculty of the <a href="https://mghcme.org/mgh-visiting/">MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry</a>. The Guide was created entirely free of pharmaceutical industry funding and made possible with the generous support of <a href="https://www.episcopalhealth.org/" target="_blank">Episcopal Health Foundation</a>. To develop the tools, we used a modified consensus-building process.
                </p>
              </div>
            </div>
            <div>
              <img src={methodImg}/>
            </div>
            <div className="grid-2">
              <div>
                <h2 style={{marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%"}}>
                    <strong>USE DISCLAIMER</strong>
                </h2>
                <p class="" style={{whitespace:"pre-wrap"}}>
                When using the Waco Guide, please keep in mind that these tools are intended for primary care settings where access to psychiatric support is limited and assume an accurate rendering of a mental disorder diagnosis. Tools are based on best available evidence; however, when there is no scientific or organizational evidence available, tools are informed by expert opinion. Additionally, the tools do not provide an exhaustive side effect list and, therefore, should not replace the typical review of side effects and interactions performed in standard practice. Recommendations do not apply to every situation and should be used to supplement, not supplant, clinical judgment and recommendations from authoritative professional bodies (e.g., American Psychiatric Association). Over-reliance on these tools may lead to errors, and no tool should substitute for independent clinical judgment informed by medical knowledge and experience.
                </p>
                                
                <h6>Version</h6>
                    <p>The embedded decision support tools (version 8.2) were last updated on 04/25/2023.</p>
                    <a href='/privacy'><h6>Privacy Policy</h6></a>
                    
              </div>
              <div>
                <div>
                <img src={ryanSpeaking} style={{width:"100%"}}/>
                </div>
              </div>
            </div>
        </div>
    )
};

export default Methodology