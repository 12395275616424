import React from 'react'
import {
    Link,
    Navigate,
    useParams
} from "react-router-dom";

import team from '../../assets/images/team.png';
import team1 from '../../assets/images/Team_Photos_group at computer2.jpg';
import team2 from '../../assets/images/Team_Photos_group cheese.jpg';
import team3 from '../../assets/images/Team_Photos_group app.jpg';
import wacoFMlogo from '../../assets/images/wacoFMlogo.jpg';
import mghvisitinglogo from '../../assets/images/mghvisitinglogo.jpg';
import building from '../../assets/images/Building Picture.jpg';
import './methodology.css';
import data from '../../data/team.json';

const Team = ({ }) => {

    let person = useParams()["*"];
    if(person!="") {
        let profile = data.find( d => d.title.toLowerCase().startsWith(person));
        if (!profile) {
            return (<Navigate to="/team" />);
        }

        return (
            <div className="container">
                <div className='grid-2' style={{gridTemplateColumns:"35% 44%"}}>
                    <div className='gridItem'>
                        <img src={require('../../assets/images/profile_assets/' + profile.headshot)} style={{maxHeight: "500px"}} />
                    </div>
                    <div className='gridItem'>
                        <div class="sqs-block-content">
                            <h4><strong>{profile.title}</strong></h4>
                            <br/>
                            <div className='profileDesc' dangerouslySetInnerHTML={{__html: profile.description}}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <h2 class="title"><strong>The Team</strong></h2>
            <div className="grid-2">
                <div>
                    <div class="sqs-block-content">
                        <div style={{width: "45%"}} />
                        <h2 className="subtitle"><strong>Editor-in-Chief</strong></h2>
                        <p><Link to="/team/ryan">Ryan Laschober, MD, FAAFP, Family Medicine</Link></p>
                        <h2 className="subtitle"><strong>Associate Editors </strong></h2>
                        <p><Link to="/team/lance">Lance Kelley, PhD, Clinical Psychology</Link>
                        <br/>
                        <Link to="/team/zach">Zachary Sartor, MD, FAAFP, Family Medicine</Link>
                        <br/>
                        <Link to="/team/susan">Susan Johnson, MD, Psychiatry</Link>
                        <br/>
                        <Link to="/team/jackson">Jackson Griggs, MD, FAAFP, Family Medicine</Link></p>
                        <h2 className="subtitle"><strong>Additional Contributors</strong></h2>
                        <p><a target='_blank' href='https://www.bswhealth.com/physician/paula-smith' className='teamLink'>Paula Smith, DO, Maternal-Fetal Medicine</a>
                        <br/>
                        <a target='_blank' href='https://residency.wacofamilymedicine.org/our-family/faculty/' className='teamLink'>Katy Wesley, MD, Ob-Gyn</a> 
                        <br/>
                        <a id="special-thanks" target='_blank' href='https://residency.wacofamilymedicine.org/our-family/faculty/' className='teamLink'>Carena Chai, DO, FAAFP, Family Medicine</a>
                        <br/>
                        <a target='_blank' href='https://residency.wacofamilymedicine.org/our-family/faculty/' className='teamLink'>Ryan Trantham, MD, Family Medicine</a></p>
                    </div>
                </div>
                <div>
                    <img src={wacoFMlogo} className="pull-right" />
                </div>
            </div>
            <div className='grid-2'>
                <div className='gridItem'>
                    <img src={mghvisitinglogo} style={{maxHeight: "500px"}} />
                </div>
                <div className='gridItem'>
                <div class="sqs-block-content">
                        <a name="special-thanks"></a>
                        <h2 className="subtitle"><strong>Special Thanks to the Faculty at <a href="https://mghcme.org/mgh-visiting/">MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry</a></strong></h2>
                        <p><a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/19398/gregory-acampora' className='teamLink'>Gregory Acampora, MD</a>
                        <br/>
                        <a target='_blank' href='https://lms.mghcme.org/node/8540/bio/1036/view' className='teamLink'>Robert Althoff, MD, PhD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/17789/joseph-biederman' className='teamLink'>Joseph Biederman, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/16762/lee-cohen' className='teamLink'>Lee Cohen, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/17797/marlene-freeman' className='teamLink'>Marlene Freeman, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.doximity.com/pub/aaron-greenstein-md' className='teamLink'>Aaron Greenstein, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/doctors/22683/scott-hadland' className='teamLink'>Scott Hadland, MD, MPH</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/doctors/18083/eric-hazen' className='teamLink'>Eric Hazen, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/doctors/20212/yoshio-kaneko' className='teamLink'>Yoshio Kaneko, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/doctors/17216/laura-kehoe' className='teamLink'>Laura Kehoe, MD, MPH</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/17039/david-mischoulon' className='teamLink'>David Mischoulon, MD, PhD</a>
                        <br/>
                        <a target='_blank' href='https://www.mcleanhospital.org/profile/holly-peek' className='teamLink'>Holly Peek, MD, MPH</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/doctors/18056/laura-fagioli-petrillo' className='teamLink'>Laura Petrillo, EdM, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/20364/edwin-raffi' className='teamLink'>Edwin Raffi, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/19510/david-rubin' className='teamLink'>David Rubin, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/17414/felicia-smith' className='teamLink'>Felicia Smith, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/16415/thomas-spencer' className='teamLink'>Thomas Spencer, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/16549/theodore-stern' className='teamLink'>Theodore Stern, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/children/doctors/20857/khadijah-watkins' className='teamLink'>Khadijah Watkins, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/doctors/16800/tim-wilens' className='teamLink'>Tim Wilens, MD</a>
                        <br/>
                        <a target='_blank' href='https://www.massgeneral.org/psychiatry/doctors/19403/john-winkelman' className='teamLink'>John Winkelman, MD, PhD</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid-2">
                <div>
                    <h2 style={{textalign:"left", whitespace:"pre-wrap"}}>
                        <strong>WACO FAMILY MEDICINE RESIDENCY</strong>
                    </h2>
                    <p class="" style={{whitespace:"pre-wrap"}}>
                        <a target='_blank' href="https://residency.wacofamilymedicine.org">Waco Family Medicine Residency</a> is one of the nation’s premier family medicine training programs. Our unique culture cultivates compassionate care to the underserved, and our uncompromising rigor forms exemplary family physicians. We boast the highest board exam pass rate in Texas and an award-winning integrated behavioral health program. Graduates practice nationally and globally in diverse settings, with many serving in areas of health professional shortage. A leader in fostering whole-person care, our program pioneered interventions such as an embedded patient fitness center, a produce prescription program, and a medical-legal partnership. In a dynamic era of health care system change, Waco leads the way in high-quality, comprehensive primary health care. Contact <a href="mailto: ProgramCoordinator@wacofhc.org">ProgramCoordinator@wacofhc.org</a> for more information about our residency program.
                    </p>
                </div>
                <div>
                    <img src={building}/>
                </div>
            </div>
        </div>
    )
};

export default Team