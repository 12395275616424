// https://www.ismp.org/sites/default/files/attachments/2017-11/tallmanletters.pdf
var tallJson = require("../data/tallMedications.json");
var tallDict = {};

function initialize() {
    for(var i=0;i<tallJson.medications.length;i++) {
        let med = tallJson.medications[i];
        tallDict[med.toLowerCase()] = med;
    }
}

function getTallMed(medication){
    if(Object.keys(tallDict).length == 0) {
        initialize();
    }
    var med = medication.toString();
    if(med.includes(' ')) {
        var medFound = false;
        var medAr = med.split(' ');
        for(var i=0;i<medAr.length;i++) {
            if(tallDict[medAr[i].toString().toLowerCase()] != null) {
                medFound = true;
                medAr[i] = tallDict[medAr[i].toString().toLowerCase()];
            }
        }
        med = medAr.join(' ');
        if(medFound) {
            return med;
        } else {
            return medication;
        }
    } else {
        return (tallDict[medication.toString().toLowerCase()] == null ? medication : tallDict[medication.toString().toLowerCase()]);
    }
}

function replaceTallMed(phrase) {
    if(Object.keys(tallDict).length == 0) {
        initialize();
    }
    
    for(var i=0;i<tallJson.medications.length;i++) {
        if(phrase.toLowerCase().includes(tallJson.medications[i].toLowerCase())) {
            phrase = phrase.replace(new RegExp(tallJson.medications[i].toLowerCase(), 'gi'), tallJson.medications[i]);
        }
    }

    var splitPhrase = phrase.split(' ');
    for(var i=0;i<splitPhrase.length;i++) {
        if(tallDict[splitPhrase[i].toLowerCase()] != null && typeof(tallDict[splitPhrase[i].toLowerCase()]) == "string") {
            splitPhrase[i] = tallDict[splitPhrase[i].toLowerCase()];
        }
    }
    return splitPhrase.join(' ');
}

if (module.exports) {
    module.exports.getTallMed = getTallMed;
    module.exports.replaceTallMed = replaceTallMed;
}