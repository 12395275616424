import React from 'react'
import texas from '../../assets/images/stateofTexas.png';
import tribune from '../../assets/images/wacoTribune.png';
import behaveHp from '../../assets/images/behavioralHealth.png';
import acpinternist from '../../assets/images/acpinternist.png';
import suicidePrevent from '../../assets/images/suicidePrevention.png';
import acpLogo from '../../assets/images/ACP_logo.png';
import integrateWeb from '../../assets/images/integrateWebinar.png';
import texasAcademy from '../../assets/images/texas_academy_2022.png';
import collabHealth from '../../assets/images/collabHealth_2022.png';
import TPCC_summit_breakout from '../../assets/images/TPCC_summit_breakout.png';
import TPCC_summit_2021 from '../../assets/images/tpcc_summit_2021.png';
import forum from '../../assets/images/theForum.png';
import texasFP from '../../assets/images/texasFP.png';
import reachInst from '../../assets/images/reach_inst.png';
import aafp from '../../assets/images/aafp.png';
import afp from '../../assets/images/AFP.png';
import tafp from '../../assets/images/tafp.png';
import mdedge from '../../assets/images/mdedge.png';
import stepsForward from '../../assets/images/stepsForward.png';
import registerAma from '../../assets/images/registerAma.png';
import simpleSolutions from '../../assets/images/simplesolutions.png';

import articles from '../../data/news.json';

function generateArticle(article) {
    if(article.link == "none") {
        return (
            <div>
                <h3 style={{color:"inherit", marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"100%", minHeight:"90px", textTransform:"none"}}>
                <strong>{article.title}</strong>
                </h3>
                <img className='newsImg' src={require('../../assets/images/' + article.image)} style={{marginTop:"10px"}}/>
            </div>
        );
    } else if(article.image.includes("youtube")) {
        return (
            <div>
                <a className='newsLink' href={article.link}>
                    <h3 style={{color:"inherit", marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"100%", minHeight:"90px", textTransform:"none"}}>
                    <strong>{article.title}</strong>
                    </h3>
                    <div style={{marginTop:"20px", display:"block", position:"relative", width:"100%", aspectRatio:"160/103"}}>
                    <iframe className='newsImg' width="100%" height="100%" src={article.image}></iframe>
                    </div>
                </a>
            </div>
        );
    } else {
        return (
            <div >
                <a className='newsLink' href={article.link}>
                    <h3 style={{color:"inherit", marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"100%", minHeight:"90px", textTransform:"none"}}>
                    <strong>{article.title}</strong>
                    </h3>
                    <img className='newsImg' src={require('../../assets/images/' + article.image)} style={{marginTop:"10px"}}/>
                </a>
            </div>
        );
    }
}

function renderArticles() {
    var index = 0;

    return (articles.map( article => {
        index++;
        if(index>2) {
            return (
                <div>
                    <div class="line"></div>
                    {generateArticle(article)}
                </div>
            );
        } else {
            return (
                <div>
                    {generateArticle(article)}
                </div>
            );
        }
    }));
}

const News = ({ }) => {
    return (
        <div className="container">
            <div className="grid-2 pb-1" style={{display:"block"}}>
                <h2 class="title" style={{textalign:"center", whitespace:"pre-wrap"}}>
                    <strong>News</strong>
                </h2>
            </div>

            <div className="grid-2 news">
                {renderArticles()}
            </div>
        </div>
    )
};

export default News;
