import React, { useState } from 'react'

const CollapseSegment = ({ content, onSelected, type }) => {
    let c = content;
    let answerSelected = (event) => {
        let value = event.currentTarget.attributes['value'].value;
        setSelected(value);
    }

    let isSelected = (c, o) => {
        return o.text === bodyText;
    }
    
    const textOpt = c.segments.map((o) => o.text);
    const [bodyText, setSelected] = useState(textOpt[0])

    let is_intro = c.id == "diagnosis" || c.id == "patient_age" || c.id == "comorbidities" || c.id == "partum";
    let outerClass = is_intro ? "" : "question-content card card-rec";
    let innerClass = is_intro ? "question-content" : "";
    return (
        <div className={"question " + outerClass}>
            <b>{c.title}</b>
            <h6 className='card-subtitle mb-2 text-muted' style={{paddingTop:"15px"}}>
            {(c.text || []).map((l, i) => (
                <React.Fragment key={i}>{l}{i < (c.text.length - 1) ? <br /> : ''}</React.Fragment>
            ))}
            </h6>
            <div className={innerClass + " segmented-options segmented-options-"}>
                {c.segments.map((o) => {
                    return (
                        <label onClick={answerSelected}
                            key={o.title}
                            isSelected={isSelected(c, o) ? "true" : "false"}
                            value={o.text}
                            className={`
                        form-control
                        ${isSelected(c, o) ? "selected" : ""}`
                            } ><h6 className='card-subtitle mb-2' style={{paddingTop:"10px"}}>{o.title}</h6></label>
                    );
                })}
            </div>
            <div style={{paddingTop:"20px"}}>
            <h6 className='card-subtitle mb-2 text-muted'>{bodyText}</h6>
            </div>
        </div>)
};

export default CollapseSegment