import React from 'react'

const Residency = ({ }) => {
    return (
        <div className="container">
            <div className="row">
            <div class="column">
                <img  src="https://images.squarespace-cdn.com/content/v1/5e14fd98ed4b31566cfea231/1614873000735-EF7DYMFNJ1GVKIDIFHYY/thumbnail_pastedImagebase640.png" alt="bulb, light yellow.png" />
              </div>
              <div class="column">
                <h2 style={{textalign:"left", whitespace:"pre-wrap"}}>
                  <strong>About Waco Family Medicine Residency</strong>
                </h2>
                <p class="" style={{whitespace:"pre-wrap"}}>
                <a href="https://residency.wacofamilymedicine.org" target="_blank">Waco Family Medicine Residency</a> is one of the nation’s premier family medicine training programs. Our unique culture cultivates compassionate care to the underserved, and our uncompromising rigor forms exemplary family physicians.  We boast the highest board exam pass rate in the state of Texas and an award-winning integrated behavioral health program. Waco Family Medicine graduates practice both nationally and globally in diverse settings, with a high proportion of physician graduates serving in health professional shortage areas. 
                </p>
                <p class="" style={{whitespace:"pre-wrap"}}>
                    A leader in fostering whole-person care, the Waco program has pioneered interventions such as an embedded patient fitness center, a produce prescription program, and a medical-legal partnership. In a dynamic era of health care system change, Waco leads the way in high-quality, comprehensive primary health care. Please contact us at ProgramCoordinator@wacofhc.org for more information about our residency program.
                </p>
              </div>
            </div>
        </div>
    )
};

export default Residency