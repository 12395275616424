import React, { Component, useRef, useState, useEffect, useMemo } from 'react'
import { Document, Page } from 'react-pdf';
import { Link } from "react-router-dom";
import SegmentedControl from "../components/SegmentedControl";
import medications from '../../data/medications.json';
import MedicationIos from '../../components/medicationIos';
import Management from '../management';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function getWidth() {
    var scWidth = window.innerWidth;//Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth);

    if(scWidth < 700) {
        //scWidth = scWidth*0.5;
        scWidth = scWidth*0.9;
    } else {
        scWidth = scWidth*0.6;
        //scWidth = scWidth*1.3;
    }
    console.log("test width " + scWidth)
    return scWidth;
}

function renderMedications(algo) {
    var meds = medications.filter(m => m.diagnosis == algo.diagnosis && m.population.includes(algo.population.toLowerCase()));
    console.log("bill test " + algo.diagnosis);
    var subs = meds.filter(m => m.name.includes("<b>") || m.name.includes("<i>"));
    var clean = meds.filter(m => (m.name.includes("<b>") || m.name.includes("<i>")) == false);
    var sortedMeds = [,];
    var medClasses = [];
    meds = meds.sort((a,b) => (a.class > b.class) ? 1 : -1)
    meds.forEach(medication => {
        if(sortedMeds[medication.class] == null) {
            sortedMeds[medication.class] = [];
        }
        sortedMeds[medication.class].push(medication);
        if(!medClasses.includes(medication.class)) {
            medClasses.push(medication.class);
        }
    })
    medClasses.sort();

    var index = 0;
    var skipNext = false;
    
    if (algo.diagnosis != "ADHD") {
        return(medClasses.map( (medClass, medIndex, medArray) => {
                if(skipNext) {
                    skipNext = false;
                    //do nothing
                } else if(medIndex < medArray.length-1 && sortedMeds[medClass].length == 1 && sortedMeds[medArray[medIndex+1]].length == 1) {
                    skipNext = true;
                    sortedMeds[medClass].sort((a,b) => (a.name > b.name) ? 1 : -1);
                    sortedMeds[medArray[medIndex+1]].sort((a,b) => (a.name > b.name) ? 1 : -1);
                    return(<div className='grid-container comp-grid'>
                        <div>
                        <b>{medClass}</b><br/>
                        {sortedMeds[medClass].map( medication => {
                            index++;
                            return (<div><MedicationIos medication={medication} showClass={false} showBranch={true} index={index} /></div>)
                        })}
                        </div>
                        <div>
                        <b>{medArray[medIndex+1]}</b><br/>
                        {sortedMeds[medArray[medIndex+1]].map( medication => {
                            index++;
                            return (<div><MedicationIos medication={medication} showClass={false} showBranch={true} index={index} /></div>)
                        })}
                        </div>
                    </div>)
                } else {
                    if(algo.diagnosis == "ADHD") {
                        sortedMeds[medClass].sort((a,b) => (a.length < b.length) ? 1 : -1);
                        var prevLength = "";
                        var curLength = "";
                        for(var i=0;i<sortedMeds[medArray[medIndex+1]].length;i++) {
                            let m = sortedMeds[medArray[medIndex+1]];
                            curLength = m.length;
                            if(prevLength != curLength) {
                                var medication = {name:"<b>" + curLength + "</b>"};
                                if(i==0) {
                                    sortedMeds.unshift(medication);
                                } else {
                                    sortedMeds[medArray[medIndex+1]].splice(i-1, 0, medication);
                                }
                            }
                            prevLength = curLength;
                        }
                    } else {
                        sortedMeds[medClass].sort((a,b) => (a.name > b.name) ? 1 : -1);
                    }
                    return(<div style={{gridAutoFlow:"dense"}} className='grid-container comp-grid'>
                        <b>{medClass}</b><br/>
                        {sortedMeds[medClass].map( medication => {
                            index++;
                            if(medication.name.includes("<b>")) {
                                return (<div><MedicationIos medication={medication} showClass={false} showBranch={true} index={index} /></div>)
                            } else {
                                return (<div><MedicationIos medication={medication} showClass={false} showBranch={true} index={index} /></div>)
                            }
                        })}
                    </div>)
                }
        }))
        var lastClass = "";
        return (meds.map( medication => {
            index++;
            if(medication.class != lastClass) {
                lastClass = medication.class;
                return (<div><b>{medication.class}</b><br/><MedicationIos medication={medication} showClass={false} index={index} /></div>)
            } else {
                return (<div><MedicationIos medication={medication} showClass={false} showBranch={true} index={index} /></div>)
            }
            }));
    } else {        
        // ADHD
        var isTitle = false;
        var medDivAR = [];
        meds.sort((a,b) => (a.class > b.class) ? 1 : (a.length < b.length) ? 1 : -1);
        
        var newMeds = [];
        var prevLength = "";
        var curLength = "";
        var prevClass = "";
        var curClass = "";
        for(var i=0;i<meds.length;i++) {
            let m = meds[i];
            curClass = m.class;
            curLength = m.length
            if(curClass != prevClass) {
                newMeds.push({name:"<b>" + curClass + "</b>"});
            }
            if(curLength != prevLength) {
                newMeds.push({name:"<i>" + curLength + "</i>"});
            }
            newMeds.push(m);
            prevClass = curClass;
            prevLength = curLength;
        }
        for(var i=0;i<newMeds.length;i++) {
            let medication = newMeds[i];
            index++;
            if(medication.name.includes("<b>") || medication.name.includes("<i>")) {
                if(index % 2 == 0) {
                    index++;
                    medDivAR.push(<div></div>);
                }
                if(medication.name.includes("<b>")) {
                    medDivAR.push(<div style={{paddingTop:"10px"}} dangerouslySetInnerHTML={({__html: medication.name})}></div>);
                } else {
                    medDivAR.push(<div style={{paddingTop:"10px"}} dangerouslySetInnerHTML={({__html: medication.name})}></div>);
                }
                isTitle = true;
            } else {
                if(isTitle && index % 2 == 0) {
                    index++;
                    medDivAR.push(<div></div>);
                    isTitle = false;
                }
                medDivAR.push(<div><MedicationIos medication={medication} showClass={false} showBranch={true} index={i} /></div>);
            }
        }
        return (medDivAR);
    }
}

var curCalResult = [];

const SlidingPdf = ({algo, isHidden, setShowPDF}) => {

    const [slideClass, setSlide] = useState("");
    const [fadeClass, setFade] = useState("");
    const [hidePDF, setPDFHide] = useState(false);
    const [hideMeds, setMedsHide] = useState(true);
    const [hideRes, setResHide] = useState(true);
    const [hideCal, setCalHide] = useState(true);
    const [pdfWidth, setpdfWidth] = useState(0);
    const [algoTool, setalgoTool] = useState("");
    const [calResult, setcalResult] = useState("");
    const [segmentValue, setsegmentValue] = useState("Resources");
    const mainPDF = useMemo(() => ({ url:'/assets/' + algo.pdf + '.pdf' }), [algo.PDF]);
    const refPDF = useMemo(() => ({ url:'/assets/' + algo.ref_pdf + '.pdf' }), [algo.refPDF]);
    
    useEffect(() => {
        //divclicked();
        if(algo.tool != "") {
            setsegmentValue("Calculator");
            switch(algo.tool) {
                case "qtc":
                    setalgoTool("ECG-QTC");
                    break;
                case "ckd":
                    setalgoTool("CKD-Epi");
                    break;
                case "pugh":
                    setalgoTool("Child-Pugh");
                    break;
                case "bmi":
                    setalgoTool("BMI (Adult)");
                    break;
                case "cows":
                    setalgoTool("COWS");
                    break;
            }
        } else {
            setalgoTool("References");
            setsegmentValue("Resources");
        }
        curCalResult = [];
        setpdfWidth(getWidth());
      }, []);


    var isAnimating = false;
    var meds = [];

    let displayCalResult = (c, index) => {
        if(curCalResult.length >= (index+1)) {
            if(curCalResult[index] !== c) {
                curCalResult[index] = c;
            } else {
                return
            }
        } else {
            curCalResult.push(c);
        }
        
        var ret = [];
        curCalResult.forEach(r => {
            ret.push(<div className="card card-rec">
            <div className="card-body">
                <h5 className="card-title">{c.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted"
                    dangerouslySetInnerHTML={{ __html: r.text }} />
            </div>
        </div>);
        });
        setcalResult(ret);
    }

    let setCalManagement = () => {
        if(algo.tool == "") {
            return
        }
        var toolName = "";
        switch(algo.tool) {
            case "qtc":
                toolName = "ECG-QTC";
                break;
            case "ckd":
                toolName = "CKD-Epi";
                break;
            case "pugh":
                toolName = "Child-Pugh";
                break;
            case "bmi":
                toolName = "BMI (Adult)";
                break;
            case "cows":
                toolName = "COWS";
                break;
        }
        return(
            <div className='row'>
                <div className="col-md-6 col-sm-6" style={{paddingTop:"30px"}}>
                    <div className="row justify-content-center d-block">
                        <h1 className='titleFont' style={{width:"80%", textAlign:"center", display:"inline-block", height:"65px", marginBottom:"20px"}}><strong>{toolName}</strong></h1>
                        <Management algo={toolName} setcalResult={displayCalResult}/>
                    </div>
                </div>
                <div className="col-md-5 col-sm-6">
                    <div className="sticky colorKey" style={{paddingTop:"120px"}}>
                        {calResult}
                    </div>
                </div>
            </div>
        )
    }

    let link_safe = (algorithm) => {
        algorithm = algorithm.replace(/\,/g, '');
        algorithm = algorithm.replace(/\s+/g, '-');
        algorithm = algorithm.replace(/\//g, '-');
        return algorithm.toLowerCase();
    }

    let divclicked = () => {
        
        if(!isAnimating) {
            isAnimating = true;
            if (slideClass === '') {
                setSlide("slideClassIn");
                setFade("fadeClassIn");
                var slider = setInterval(() => {
                    isAnimating = false;
                    clearInterval(slider);
                }, 1200);
            }
            else {
                setSlide("slideClassOut");
                setFade("fadeClassOut");
                var slider = setInterval(() => {
                    setShowPDF(false);
                    isAnimating = false;
                    clearInterval(slider);
                }, 1200);
            }
        }
    }

    let changeFilter = (val) => {
        switch(val) {
            case "Tool":
                setPDFHide(false);
                setMedsHide(true);
                setResHide(true);
                setCalHide(true);
                break;
            case "Medications":
                setPDFHide(true);
                setMedsHide(false);
                setResHide(true);
                setCalHide(true);
                break;
            case "Resources":
                setPDFHide(true);
                setMedsHide(true);
                setResHide(false);
                setCalHide(true);
                break;
            case "Calculator":
                setPDFHide(true);
                setMedsHide(true);
                setCalHide(false);
                break;
        }
    }

    return(
        <div>
            
        <div className="desktop">
            <div className="stickyParent" style={{width:pdfWidth, pointerEvents:"none"}}>
                <div id="fadeDiv" className={"stickyMenu grid3 " + fadeClass} style={{width:"100%", backgroundColor:"#ffffff", paddingTop:"30px", paddingLeft:"15px", transform:"translateY(-20px)", pointerEvents:"all", gridTemplateColumns: "10% auto 28%"}}>
                    <input style={{marginRight:"0px", display:"inline-block", height:"65px", minWidth:"125px", marginBottom:"15px"}} id="fadeDiv" className={"btn btn-primary " + fadeClass} type="button" onClick={() => divclicked()} value="< Back" />
                    <div style={{display:"inline-block", transform:"translateY(-10px)"}}>
                    <SegmentedControl style={{width:"60%", margin:"0px"}}
                        name="group-1"
                        callback={(val) => {changeFilter(val)}}
                        controlRef={useRef()}
                        className="controls-containerPDF"
                        subClassName="controlsPDF"
                        segments={(algo.ref_pdf == "" && algo.tool == "") ? [
                            {
                                label: "Tool",
                                value: "Tool",
                                ref: useRef()
                            },
                            {
                                label: "Medications",
                                value: "Medications",
                                ref: useRef()
                            }
                        ] :  [
                                {
                                    label: "Tool",
                                    value: "Tool",
                                    ref: useRef()
                                },
                                {
                                    label: "Medications",
                                    value: "Medications",
                                    ref: useRef()
                                },
                                {
                                    label: algoTool,
                                    value: segmentValue,
                                    ref: useRef()
                                }
                            ]}
                    />
                    </div>
                    <Link to={"/" + "adult" + "/" + link_safe(algo.title)} style={{display:"inline-block", height:"65px", marginBottom:"15px", marginRight:"15px", float:"right"}} id="fadeDiv" className={"btn btn-primary " + fadeClass} type="button">{"Guided View"}</Link>
            
                </div>
            </div>
        </div>
        <div className="mobile">
            <input style={{width:"40%", marginRight:"15px", display:"inline-block", height:"40px", minWidth:"125px"}} id="fadeDiv" className={"btn btn-sm " + fadeClass} type="button" onClick={() => divclicked()} value="< Back" />
            <Link to={"/" + "adult" + "/" + link_safe(algo.title)} style={{width:"40%", display:"inline-block", height:"40px", paddingTop:"12px"}} id="fadeDiv" className={"btn btn-sm " + fadeClass} type="button">{"Guided View"}</Link>
            <div id="fadeDiv" className={"" + fadeClass} style={{width:"100%", transform:"translateY(30px)", marginTop:"-20px", marginBottom:"50px"}}>
                <SegmentedControl style={{width:"100%", margin:"0px"}}
                    name="group-1"
                    callback={(val) => {changeFilter(val)}}
                    controlRef={useRef()}
                    className="controls-container-sm"
                    subClassName={"controls-sm"}
                    segmentClassName="segment-sm"
                    segments={algo.ref_pdf == "" ? [
                        {
                            label: "Tool",
                            value: "Tool",
                            ref: useRef()
                        },
                        {
                            label: "Medications",
                            value: "Medications",
                            ref: useRef()
                        }
                    ] :  [
                            {
                                label: "Tool",
                                value: "Tool",
                                ref: useRef()
                            },
                            {
                                label: "Medications",
                                value: "Medications",
                                ref: useRef()
                            },
                            {
                                label: "References",
                                value: "Resources",
                                ref: useRef()
                            }
                        ]}
                />
            </div>
        </div>
        <div className={"card " + slideClass} id="slideDiv" hidden={hidePDF} style={{width:pdfWidth}}>
            <a target="_blank" href={'/assets/' + algo.pdf + '.pdf'}>
            <Document 
                file={mainPDF}
            >
                <Page pageNumber={1} width={(pdfWidth)-20} onRenderSuccess={divclicked} renderAnnotationLayer={false} renderInteractiveForms={false} />
            </Document>
            </a>
        </div>
        <div className={"card-compMed " + slideClass} id="slideDiv" hidden={hideMeds} style={{width:"100%"}}>
            <div style={{paddingLeft:"20px", paddingTop:"20px"}}>
                <h2>Medications</h2>
                <div>
                    {renderMedications(algo)}
                </div>
            </div>
        </div>
        <div className={"card " + slideClass} id="slideDiv" hidden={hideRes} style={{width:pdfWidth}}>
            <a target="_blank" href={'/assets/' + algo.ref_pdf + '.pdf'}>
            <Document 
                file={refPDF}
            >
                <Page pageNumber={1} width={(pdfWidth)-20} renderInteractiveForms={false} />
            </Document>
            </a>
        </div>
        {hideCal ? "" : setCalManagement()}

        </div>
    )
}

export default SlidingPdf