export const ballHelper = {
    getAngle() {
        return Math.floor(Math.random() * 360);
    },
    getColor() {
        let colors = [`#FFD000`, `#0CBDB2`, `#9D1992`, `#16A26B`, `#FEAA0D`, `#047676`, `#64075C`, `#07623E`]
        let i = Math.floor(Math.random() * (colors.length - 0 + 1) + 0);
        return colors[i];
    },
    getRadius() {
        return Math.floor(Math.random() * 30) + 10;
    },
    getSpeed() {
        return ((Math.random() * 10)/100)+0.009;
    }
};