import React, { useState } from 'react'
import waco from '../service/waco';
import downArrow from '../assets/images/downArrow.png';

var medOne;
var medTwo;

const MedSwitch = ({ content, setSwitch }) => {

    let c = content;
    let medications = waco.getVariable("medications").sort();

    let onChange1 = (event) => {
        medOne = event.target.value;
        setSwitch("medOne", medOne);
    }

    let onChange2 = (event) => {
        medTwo = event.target.value;
        setSwitch("medTwo", medTwo);
    }

    return (
        <div className={"card "} style={{padding:"20px 10px"}}>
            <div>
                <div style={{padding:"10px"}}>
                    <select className="form-control" id={c.id} onChange={onChange1}> 
                        <option key="Select" value="Select">Select Medication</option>
                        {medications.map(med => {
                            med = med.replaceAll("\'", "");
                            return (<option key={med} value={med}>{med}</option>);
                        })}
                    </select>
                </div>
                <div align="center"><img style={{height:"25px"}} src={downArrow}/></div>
                <div style={{padding:"10px"}}>
                    <select className="form-control" id={c.id} onChange={onChange2}> 
                        <option key="Select" value="Select">Select Medication</option>
                        {medications.map(med => {
                            med = med.replaceAll("\'", "");
                            return (<option key={med} value={med}>{med}</option>);
                        })}
                    </select>
                </div>
            </div>
        </div>)
};

export default MedSwitch