import React, { useRef, useState, useEffect } from "react";
import "../management/segment.css";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({
  name,
  segments,
  callback,
  defaultIndex = 0,
  controlRef,
  className,
  subClassName,
  segmentClassName = "segment",
  margin = "auto"
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef();

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value, index) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className={"" + className} ref={controlRef}>
      <div className={`${subClassName} ${componentReady.current ? "ready" : "idle"}`} style={{margin:margin}}>
        {segments.map((item, i) => (
          <div
            key={item.value}
            className={`${segmentClassName} ${i === activeIndex ? "active" : "inactive"}`}
            ref={item.ref}
          >
            <input
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{item.label}</label>
            {item.image == null ? null : <img className='segment-img' src={item.image}/>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedControl;
