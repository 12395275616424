import React, { useState, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from "react-router-dom";
import team from '../../assets/images/wacoMobile.png'

const Contact = ({ }) => {
       
    const [submitDisabled, setDisabled] = useState(true);
    const captchaRef = useRef(null)

    const [type, setType] = useState("question");
    const [email, setEmail] = useState("");
    const [audience, setAudience] = useState("");
    const [name, setName] = useState("");
    const [event, setEvent] = useState("");
    const [description, setDescription] = useState("");


    let handleChange = (event) => {
        console.log("test  " + event.target.name)
        switch(event.target.name) {
            case "email":
                setEmail(event.target.value);
            break;
            case "name":
                setName(event.target.value);
            break;
            case "audience":
                setAudience(event.target.value);
            break;
            case "event":
                setEvent(event.target.value);
            break;
            case "body":
                setDescription(event.target.value);
            break;
        }
    }

    let handleSubmit = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        setDisabled(true);

        var message = description + " " + event + " " + audience;

        var formSubmit = {
            "type":"inquiry",
            "title":type,
            "email":email,
            "name":name,
            "message":message
        }

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlfa2V5IjoiMjc4Njg1MjQtMmE5Ny00NGJhLTkxYjAtNGU0NzZkZjcyYmE5Iiwib3JnIjoiV2FjbyIsImlhdCI6MTY2NTUwMzk4MCwianRpIjoiZmNlNTkwYmEtODg2MC00Nzk1LWJkNTUtYTFiNzRlZjI2MjI2In0.FvFSYrm1aDnnxRQuqg7sbY35k61HsjsQ_tQcKfULGm0' },
            body: JSON.stringify(formSubmit)
        };

        fetch('https://api.ascaapps.com/v1/tickets', requestOptions)
            .then(response => response.json())
            .then(data => emailSent());
    }

    let emailSent = () => {
        alert("Message Sent: Thank you for your feedback!")
    }

    let changeFilter = (event) => {
        setType(event.target.value);
    }

    let captchaDone = () => {
        setDisabled(false);
    }

    return (
        <div className="container">
            <div className="grid-2">
                <div>
                    <h2><strong>We'd love to hear from you!</strong></h2>
                    <p>
                        Have a look at our <Link to='/faq'>frequently asked questions</Link>! Feel free to invite us to speak, offer suggestions, ask the team a question. Please consult your physician if you have a concern about your personal health.
                    </p>
                    <div>
                    <select className="form-control" id="test" onChange={changeFilter} style={{marginBottom:"25px", marginLeft:"4px"}}> 
                        <option selected key="question" value="question">I have a question about the Waco Guide</option>
                        <option key="event" value="event">I want the Waco Guide team to speak at an event</option>
                        <option key="suggestion" value="suggestion">I have a suggestion</option>
                        <option key="other" value="other">Other</option>
                    </select>
                    
                    <form
                        method="POST"
                        encType="text/plain"
                        name="EmailForm" onSubmit={handleSubmit}>
                        <input className='form-control contact contact-input' type="text" placeholder='Contact Email' name="email" onChange={handleChange} value={email} autoComplete='email' />
                        <input className='form-control contact contact-input' type="text" placeholder='Name (Optional)' name="name" onChange={handleChange} value={name} autoComplete='name' />
                        {(type=='event') ? (<input className='form-control contact contact-input' type="text" name="audience" placeholder='Who is the audience (Optional)' onChange={handleChange} value={audience} />) : null}
                        {(type=='event') ? (<input className='form-control contact contact-input' type="text" name="event" placeholder='Where is the event (Optional)' onChange={handleChange} value={event} />) : null}
                        <textarea className='form-control contact contact-area' placeholder='Enter Text' name="body" onChange={handleChange} value={description} />
                        <ReCAPTCHA
                        sitekey="6Lc8nwwlAAAAABwXfnb94UsDmuEPr99eLufEY26u"
                        ref={captchaRef}
                        onChange={captchaDone}
                        />
                        <input className='form-control contact-submit' type="submit" value="Submit" disabled={submitDisabled} style={{marginLeft:"4px", marginTop:"8px"}}/>
                    </form>
                    </div>
                </div>
                <div>
                    <img src={team}/>
                </div>
            </div>
        </div>
    )
};

export default Contact