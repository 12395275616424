/*
*
* DO NOT EDIT THIS FILE
* THIS JAVASCRIPT LOGIC IS SHARED
* BETWEEN MULTIPLE PLATFORMS
*
*/

var jsonData = null;
var categories = null;
var currentCategory = null;
var vars = {};
var diagnosis = "";
var age = "";
var comorbidity = "";

var log = function(e) {
console.log(e);
}

function setAlgorithm(data) {
  jsonData = data;
  categories = data.sections;
  currentCategory = categories[0].category;

  var variables = Object.keys(data.variables);
  for (var i = 0; i < variables.length; i++) {
    var key = variables[i];
    var value = data.variables[key];
    initializeVariable(key, value);
  }
}

function isExpressionTrue(expression, calc) {
  try {
    if (expression === "true") {
      return true;
    }
    if (expression === "false") {
      return false;
    }
    if (expression === true) {
      return true;
    }
    if (expression === false) {
      return false;
    }

    var cresult = 'undefined';
    if (calc) {
      cresult = evaulateExpression(calc);
    }
      
    eval("value = " + cresult + ";");
    var result = evaulateExpression(expression);
    return result === true;
  } catch (e) {
    log(e);
    return false;
  }
}

function evaulateExpression(expression) {
  try {
    if (expression === "") {
      return undefined;
    }

    var fexpression = "return " + expression + ";";
    var result = new Function(fexpression)();
    return result;
  } catch (e) {
    log([expression, e]);
    return undefined;
  }
}

function setVariable(id, value) {
  if (vars[id] !== value) {
    vars[id] = value;
    var tof = typeof (value);
    if (tof === "string") {
        value = value.replace(/\"/g, "\\\"");
        value = value.replace(/\'/g, "\\\'");
        log("set " + id + " to '" + value + "'");
        eval(id + " = '" + value + "';");
    } else if (tof == "number" || tof == "boolean") {
        log("set " + id + " to " + value);
        eval(id + " = " + value + ";");
    } else {
        value = JSON.stringify(value);
        log("set " + id + " to " + value);
        eval(id + " = " + value + ";");
    }
  }
}

function getVariable(id) {
    if(vars[id] == null){
        return null
    } else {
        return vars[id]
    }
}


function initializeVariable(id, value) {
  if (typeof vars[id] === "undefined") {
    setVariable(id, value);
  }
}

function each(arr, callback) {
  if (typeof (arr) === 'undefined') {
    return;
  }

  for (var key = 0; key < arr.length; key += 1) {
    var val = arr[key];
    callback(key, val);
  }
}

function evaulateDerived() {

  if (!jsonData) {
    return;
  }

  var html = '';
  for (var i = 0; i < jsonData.derived.length; i += 1) {
    var derived = jsonData.derived[i];
    var keys = Object.keys(derived);
    for (var j = 0; j < keys.length; j += 1) {
      var variable = keys[j];
      var value = evaulateExpression(derived[variable]);
      setVariable(variable, value);
    }
  }
}


function evaulateSection(state) {

  if (!jsonData) {
    return null;
  }

  for (var i = 0; i < jsonData.sections.length; i += 1) {
    var section = jsonData.sections[i];
    if (section.state === state && isExpressionTrue(section.valid)) {
      return section;
    }
  }
  return null;
}

function formatText(text) {

  if (typeof (text) === 'string') {
    return [text];
  }

  var items = [];
  each(text, function (i, tn) {
    if (isExpressionTrue(tn.visible)) {
      items.push(tn.text);
    }
  });

  return items;
}

function evaluateChoices(choices) {
    var items = [];
    each(choices, function (i, choice) {
        var visible = true;
        if (choice.visible) {
          visible = isExpressionTrue(choice.visible);
        }
        var enabled = true;
        if (choice.enabled) {
            enabled = isExpressionTrue(choice.enabled);
        } else if (choice.enabled === false) {
            enabled = false
        }
        if (visible) {
            items.push({text: choice.text, value: choice.value, enabled: enabled});
        }
    });
    return items;
}

function formatGroups(groups) {

  var items = [];
  each(groups, function (i, group) {
    var visible = true;
    if (group.visible) {
      visible = isExpressionTrue(group.visible);
    }
    if (visible) {
        items.push({
          id: group.id,
          type: group.type,
          choices: evaluateChoices(group.choices),
          value: vars[group.id]
        });
    }
  });

  return items;
}

function evaluateChecked(id, vars) {
  var prefix = id + "_";

  var checked = [];
  var keys = Object.keys(vars);
  each(keys, function(i, key) {
    if (key.startsWith(prefix) && !key.endsWith("_v") && vars[key] === true) {
      checked.push(
        key.substring(prefix.length)
      );
    }
  });
  return checked;
}

function evaluateItems(val) {
  var items = [];
  var vs = isExpressionTrue(val.visible, val.calc);
  setVariable(val.id + "_v", vs);
    
  if (vs) {
      
    var rText = val.text
    if(val.varInsert) {
        for(var i=0;i<val.varInsert.length;i++) {
            rText = rText.replace(/<var>/, evaulateExpression(val.varInsert[i]))
        }
    }
    if (val.type == "choice" || val.type == "segment") {
      items.push({
        id: val.id,
        type: val.type,
        text: formatText(val.text),
        choices: evaluateChoices(val.choices),
        value: vars[val.id]
      });
    } else if(val.type == "set")
    {
        items.push({
          id: val.id,
          type: val.type,
          text: formatText(""),
          choices: evaluateChoices(val.choices),
          value: vars[val.id]
        });
    } else if (val.type == "checkboxes" || val.type == "switch") {

      items.push({
        id: val.id,
        type: val.type,
        title: val.title,
        text: formatText(val.text),
        choices: evaluateChoices(val.choices),
        checked: evaluateChecked(val.id, vars),
        groups: formatGroups(val.groups),
        value: vars[val.id]
      });

    } else if (val.type == "select") {

      items.push({
        id: val.id,
        type: val.type,
        text: formatText(val.text),
        choices: evaluateChoices(val.choices),
        value: vars[val.id]
      });

    } else if (val.type == "radio") {

      items.push({
        style: val.style,
        id: val.id,
        type: val.type,
        info: val.info,
        text: formatText(val.text),
        groups: formatGroups(val.groups)
      });
    }
    else if (val.type == "overlay" || val.type == "title") // Shows overlay to follow up or confirm diagnosis. this is the end of the diagnosis at the moment
    {
      items.push({
        uid: val.uid,
        id: val.id,
        type: val.type,
        figure: val.figure,
        text: formatText(rText),
        meds: val.meds,
        therapies: val.therapies
      });
    }
    else if (val.type == "collapse") // Shows overlay to follow up or confirm diagnosis. this is the end of the diagnosis at the moment
    {
        items.push({
            id: val.id,
            title: val.title,
            type: val.type,
            text: formatText(val.text),
            meds: val.meds
        });
    }
    else if (val.type == "collapse_segment") // Shows overlay to follow up or confirm diagnosis. this is the end of the diagnosis at the moment
    {
        items.push({
            id: val.id,
            title: val.title,
            type: val.type,
            text: formatText(val.text),
            segments: val.segments
        });
    } else {
        items.push({
          uid: val.uid,
          id: val.id,
          type: val.type,
          choices: evaluateChoices(val.choices),
          value: vars[val.id],
          text: formatText(rText)
        });
    }
  }

  return items;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function evaluateContent(section) {
  var meds = [];
  var thers = [];
  var items = [];
  each(section.content, function (key, val) {
    var pageItems = evaluateItems(val);
    items = items.concat(pageItems);
  });
    
each(items, function (key, val) {
    if (val.meds) {
        meds = meds.concat(val.meds);
        delete val.meds;
    }
    if (val.therapies) {
        thers = thers.concat(val.therapies);
        delete val.therapies;
    }
});
    
  return {
    uid: section.uid,
    title: section.title,
    type: section.type,
    figure: section.figure,
    content: items,
    branch: section.branch,
    medications: meds, // Removed .filter(onlyUnique) so ADHD could have title cells in medications
    therapies: thers.filter(onlyUnique),
    variables: vars
  }
}


function reevaluate(state) {

  evaulateDerived();
  var section = evaulateSection(state);
  if (section == null) {
     // return section;
      
        section = {
          title: 'Recommendation',
          type: null,
          figure: ['n/a'],
          content: [{
                            "visible": true,
                            "type": "overlay",
                            "text": "Psychiatric consultation, if available"
                        }],
          branch: '',
          medications: [],
          variables: vars
        };
  }

  return evaluateContent(section);
}

function startOver()
{
    setAlgorithm(jsonData);
    vars = {};
    log(JSON.stringify(jsonData.variables));
}

if (module.exports) {
    module.exports.startOver = startOver;
    module.exports.setAlgorithm = setAlgorithm;
    module.exports.setVariable = setVariable;
    module.exports.getVariable = getVariable;
    module.exports.reevaluate = reevaluate;
}
