import React from 'react';
import {
    BrowserRouter, Route, Navigate, Routes, NavLink, Outlet,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

class Nav extends React.Component {
    
    render() {
        let isDownload = this.props.isDownload;
        let isActive = this.props.isActive;

        return (
            <ul className="navbar-nav ml-auto">
                {isActive ? (
                    <li className={isDownload ? 'nav-item' : 'nav-item active'}>
                        <NavLink className="page-scroll" to={this.props.page}>Interactive Tool</NavLink>
                    </li>
                ) : null}
                <li className={isDownload ? 'nav-item active' : 'nav-item'}>
                    <NavLink className="page-scroll" to={this.props.page + "/download"}>Offline Tool</NavLink>
                </li>
            </ul>
        )
    };
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let page = useParams()["*"];
        console.log(location.pathname, page);

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

Nav = withRouter(Nav);

export default Nav;