import React from 'react'
import './methodology.css';
import { Link } from 'react-router-dom';
import methodImg from '../../assets/images/methodology_chart.png';

const FAQ = ({ }) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
    return (
        <div className="container">
            <h2 style={{textAlign:"center"}}><strong>Frequently Asked Questions</strong></h2>
            <div>
              <h2 style={{marginTop:"15px"}}><strong>General</strong></h2>
              <div className='faqQuestion' key={"quest1"}  href={"#q1"} role="button" aria-expanded="false" aria-controls={"q1"}>
                  <div className='ignoreClick'>
                      <h2 className='subtitle'>
                          <strong>How often are tools reviewed and updated?</strong>
                      </h2>
                  </div>
                  <div>
                      <div id={"q1"}>
                      <p className='answers' style={{whitespace:"pre-wrap"}}>
                      The editorial team is committed to continuous review to ensure fidelity to relevant updates in the psychiatric literature. We evaluate all CDSTs every two years alongside consultation with faculty of the MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry and modify the tools as needed using our consensus-building method. Additionally, we encourage users to provide feedback on the CDSTs in a real-time peer-review process available through the website and mobile app. 
                      </p>
                      </div>
                  </div>
              </div>
              
              <div className='faqQuestion' key={"quest2"}  href={"#q2"} role="button" aria-expanded="false" aria-controls={"q2"}>
                  <div className='ignoreClick'>
                  <h2 className='subtitle'>
                          <strong>Do you offer presentations or trainings to medical groups and organizations on using The Waco Guide in clinical practice?</strong>
                      </h2>
                  </div>
                  <div>
                      <div id={"q2"}>
                        <p className='answers' style={{whitespace:"pre-wrap"}}>
                        Yes! Please submit a request via our <Link to="/contact-us">Contact Us</Link> page.
                        </p>
                      </div>
                  </div>
              </div>
              <div className='faqQuestion' key={"quest3"}  href={"#q3"} role="button" aria-expanded="false" aria-controls={"q3"}>
                  <div className='ignoreClick'>
                  <h2 className='subtitle'>
                          <strong>I can’t find the References for some tools – am I missing something?</strong>
                      </h2>
                  </div>
                  <div>
                      <div id={"q3"}>
                        <p className='answers' style={{whitespace:"pre-wrap"}}>
                        While many of our tools include strength of recommendation ratings and accompanying key references, we are still in the process of developing these for some tools (e.g., tools tailored to special populations). 
                        </p>
                      </div>
                  </div>
              </div>
              <div className='faqQuestion' key={"quest4"}  href={"#q4"} role="button" aria-expanded="false" aria-controls={"q4"}>
                  <div className='ignoreClick'>
                  <h2 className='subtitle'>
                          <strong>How should I cite The Waco Guide in a presentation or paper?</strong>
                      </h2>
                  </div>
                  <div>
                      <div id={"q4"}>
                        <p className='answers' style={{whitespace:"pre-wrap"}}>
                          <ul style={{listStyleType:"circle"}}>
                            <li className='answers'><i>App, AMA Style:</i></li>
                            <ul>
                              <li className='answers'>The Waco Guide to Psychopharmacology in Primary Care® app. Version 7.0. Heart of Texas Community Health Center. Updated September 1, 2022. </li>
                            </ul>
                            <li className='answers'><i>App, APA Style:</i></li>
                            <ul>
                              <li className='answers'>Laschober, R. D., Kelley, L. P., Sartor, Z. R., Johnson, S., and Griggs, J. O. (2022). The Waco Guide to Psychopharmacology in Primary Care®. (Version 7.0) [Mobile app]. https://wacoguide.org/app</li>
                            </ul>
                            <li className='answers'><i>Website, AMA Style:</i></li>
                            <ul>
                              <li className='answers'>Laschober RD, Kelley LP, Sartor ZR, Johnson S, Griggs JO. Waco Guide to Psychopharmacology in Primary Care. Updated September 1, 2022. Accessed September 15, 2022. https://wacoguide.org/</li>
                            </ul>
                            <li className='answers'><i>Website, APA Style:</i></li>
                            <ul>
                              <li className='answers'>Laschober, R. D., Kelley, L. P., Sartor, Z. R., Johnson, S., and Griggs, J. O. (2022, September). Homepage. The Waco Guide to Psychopharmacology in Primary Care®. https://wacoguide.org/</li>
                            </ul>
                            <li className='answers'><i>Book, AMA Style:</i></li>
                            <ul>
                              <li className='answers'>Laschober RD, Kelley LP, Sartor ZR, Johnson S, Griggs JO. The Waco Guide to Psychopharmacology in Primary Care®.  Heart of Texas Community Health Center; 2022.</li>
                            </ul>
                            <li className='answers'><i>Book, APA Style:</i></li>
                            <ul>
                              <li className='answers'>Laschober, R. D., Kelley, L. P., Sartor, Z. R., Johnson, S., and Griggs, J. O. (2022). The Waco Guide to Psychopharmacology in Primary Care®. Heart of Texas Community Health Center.</li>
                            </ul>
                          </ul>
                        </p>
                      </div>
                  </div>
                  <h2 style={{marginTop:"35px"}}><strong>Clinical</strong></h2>
                <div className='faqQuestion' key={"quest5"}  href={"#q5"} role="button" aria-expanded="false" aria-controls={"q5"}>
                  <div className='ignoreClick'>
                      <h2 className='subtitle'>
                          <strong>How should we consider treating an individual with Bipolar Depression but with a history of recent or severe mania?</strong>
                      </h2>
                  </div>
                  <div>
                      <div id={"q5"}>
                      <p className='answers' style={{whitespace:"pre-wrap"}}>
                      A patient with Bipolar Depression with a history of recent or severe mania should still be treated (or prophylaxis) for mania while simultaneously treating their depression. This may be accomplished by a single agent (such as quetiapine) or two agents (such as Aripiprazole and Lamotrigine).
                      </p>
                      </div>
                  </div>
              </div>
              <div className='faqQuestion' key={"quest6"}  href={"#q6"} role="button" aria-expanded="false" aria-controls={"q6"}>
                  <div className='ignoreClick'>
                      <h2 className='subtitle'>
                          <strong>Why are Atypical Antipsychotics chosen as first line for Bipolar Mania rather than mood stabilizers?</strong>
                      </h2>
                  </div>
                  <div>
                      <div id={"q6"}>
                      <p className='answers' style={{whitespace:"pre-wrap"}}>
                        Atypical Antipsychotics (AAP) were placed as first choice pharmacotherapy by the Waco Guide editorial team and the faculty of <a href="https://mghcme.org/mgh-visiting/">MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry</a> for the following reasons: 
                        <ul style={{listStyleType:"circle"}}>
                          <li className='answers'>Published Data: Multiple meta-analyses highlight either the superiority, or non-inferiority, of AAP compared to mood stabilizers. Most notably is a <a href='/assets/Lancet 2011_meta-analysis (AAP for bipolar mania).pdf' target="_blank">2011 Lancet article</a> that states in the conclusion: “Overall, antipsychotic drugs were significantly more effective than mood stabilisers.”  Additional notable studies include those from <a href='assets/Yildiz et al 2011 Neuropsychopharmacology_Efficacy of Antimanic Treatments-  Meta-analysis.pdf' target="blank">Yildiz et al, 2011 in Neuropsychopharmacology</a> concluding "...a limited number of direct comparisons indicated that antipsychotic agents (SGAs or haloperidol) may have had somewhat superior apparent efficacy or more rapid action than the group of mood stabilizers tested (carbamazepine, lithium, valproate)."  This was again noted in <a href='assets/Yildiz et al 2015 Psychological Medicine.pdf' target="blank">Yildez et al’s, 2015 article in Psychological Medicine</a>.</li>
                          <li className='answers'>Guidelines: National Institute for Health and Care Excellence (NICE), Canadian Network for Mood and Anxiety Treatments (CANMAT), and International Society for Bipolar Disorders all include AAP as the sole first-line treatment option or one of the first-line treatment options for the treatment of acute mania. </li>
                          <li className='answers'>Tolerability: <a href='assets/Yildiz et al 2015 Psychological Medicine.pdf' target="blank">Yildez et al’s, 2015 article in Psychological Medicine</a> highlights the general increased tolerability of the majority of AAP's as opposed to mood stabilizers.  </li>
                          <li className='answers'>Expert Opinion: Our consulting expert psychiatrists at <a href="https://mghcme.org/mgh-visiting/">MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry</a> felt strongly that these should be considered our first-choice pharmacotherapy for the majority of primary care clinicians. </li>
                          <li className='answers'>AAP are often more practical in the primary care setting based on tolerability, cost, safety, and prescriber familiarity. </li>
                        </ul>
                      </p>
                      </div>
                  </div>
              </div>
              </div>
            </div>
        </div>
    )
};

export default FAQ