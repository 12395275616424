import React from 'react'
import diagImg from "../assets/images/diagnosis.png"

const Select = ({ content, onSelected, defaultSelected }) => {
    let c = content;
    let onChange = (event) => {
        let ix = parseInt(event.target.value);
        let choice = c.choices[ix];
        onSelected(c.id, choice);
    }

    return (
        <div className="question">
            <div className="question-content">
                
                <h3 style={{padding:"0 0 10px 0"}}>{c.text == "Diagnosis" ? <img style={{width:"32px", marginRight:"10px"}} src={diagImg}/> : null}{c.text}</h3>
                <select className="form-control" id={c.id} onChange={onChange}> 
                    {c.choices.map((o, ix) => {
                        if(o.text == defaultSelected) {
                            return <option selected key={o.text} value={ix}>{o.text}</option>
                        } else {
                            return <option key={o.text} value={ix}>{o.text}</option>
                        }
                    })}
                </select>
            </div>
        </div>)
};

export default Select