import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import WelcomeSelect from '../components/welcomeSelect'

let diagnoses = require('../../data/diagnoses.json');


const Welcome = ({ }) => {

    return (
        <div>
            <WelcomeSelect diagnoses={diagnoses}/>
            
        </div>
    )
};

export default Welcome