import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import facebook from '../../assets/images/facebook.png';
import twitter from '../../assets/images/twitter.png';
import instagram from '../../assets/images/insta.png';

class MobileNav extends Component{

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.state = {
            file: null,
        }
        this.openNav = this.openNav.bind(this)
        this.closeNav = this.closeNav.bind(this)
    }

    openNav = () => {
        document.getElementById("mySidebar").style.width = "250px";
    }
    
    closeNav = () => {
        document.getElementById("mySidebar").style.width = "0";
    }
    
    render() {
        
        return(
            <div className="mobile">
                <button className="openbtn" onClick={this.openNav}>☰</button>  
                <div id="mySidebar" className="sidebar">
                    <a className="closebtn" onClick={this.closeNav}>×</a>
                    <ul className="navbar-nav mr-auto ml-auto">
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/about-guide" onClick={this.closeNav}>About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/methodology" onClick={this.closeNav}>Methodology</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/theteam" onClick={this.closeNav}>The Team</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/news" onClick={this.closeNav}>News</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/conference" onClick={this.closeNav}>Conference</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/contact-us" onClick={this.closeNav}>Contact Us</Link>
                        </li>
                    </ul>
                    <div style={{width:"80%", marginLeft:"15px"}}>
                        <a className='column30 social-icon' href='https://www.facebook.com/profile.php?id=100067715317391' target="_blank" style={{padding: "5px"}}><img src={facebook} /></a>
                        <a className='column30 social-icon' href='https://mobile.twitter.com/wacoguide' target="_blank" style={{padding: "5px"}}><img src={twitter} /></a>
                        <a className='column30 social-icon' href='https://www.instagram.com/wacoguide/' target="_blank" style={{padding: "5px"}}><img src={instagram} /></a>
                    </div>
                </div>
            </div>
        )
    }
    
}

export default MobileNav