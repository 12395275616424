import React from 'react'
import ageImg from '../assets/images/patient_age.png'

const Segment = ({ content, diagnosis, onSelected, type }) => {
    let c = content;
    let answerSelected = (event) => {
        let value = event.currentTarget.attributes['value'].value;
        let selected = event.currentTarget.attributes['isselected'].value;
        let disabled = event.currentTarget.attributes['isdisabled'].value;
        if (disabled === "true") {
            return;
        }

        if (selected == "true") {
            onSelected(c.id, { text: "" });
        } else {
            onSelected(c.id, { text: value });
        }
    }

    let isSelected = (c, o) => {
        return c.value === (o.value || o.text);
    }
    let isEnabled = (o) => {
        return o.enabled !== false;
    }

    let checkforHtml = () => {
        if(c.text != null) {
            let newText = "" + c.text;
            if(newText.includes("<b>") || newText.includes("<i>") || newText.includes("<br>")) {
                return(<div dangerouslySetInnerHTML={({__html: c.text})}></div>)
            } else {
                return((c.text || []).map((l, i) => (
                    <React.Fragment key={i}>{l}{i < (c.text.length - 1) ? <br /> : ''}</React.Fragment>
                )))
            }
        } else {
            return((c.text || []).map((l, i) => (
                <React.Fragment key={i}>{l}{i < (c.text.length - 1) ? <br /> : ''}</React.Fragment>
            )))
        }
    }

    const choicesClasses =
        c.type == "segment" || (c.choices.length == 0)
            ? "segmented-options-" + c.choices.length
            : "segmented-options-vertical segmented-options-vertical-" +
            c.choices.length;

    let innerClass = (type != "radio") ? "question-content" : "";
    
    return (
        <div className={"question " + innerClass}>
            <h3 style={{padding:"0 0 10px 0"}}>{c.text == "Age" ? <img style={{width:"32px", marginRight:"10px"}} src={ageImg}/> : null}{checkforHtml()}</h3>
            <div className={ " segmented-options " + choicesClasses}>
                {c.choices.map((o) => {
                    return (
                        <label onClick={answerSelected}
                            key={o.text}
                            isselected={isSelected(c, o) ? "true" : "false"}
                            isdisabled={!isEnabled(o) ? "true" : "false"}
                            value={o.value || o.text}
                            className={`
                        form-control
                        ${isSelected(c, o) ? "selected" : ""}
                        ${isEnabled(o) ? "" : "disabled"}`
                            } dangerouslySetInnerHTML={{ __html: o.text}}></label>
                    );
                })}
            </div>
        </div>)
};

export default Segment