import React from 'react'
import wacoGrid from '../../assets/images/wacoGrid.png';

const Usage = ({ }) => {
    return (
        <div className="container">
            <div className="row">
            <div class="column">
                    <img src={wacoGrid} style={{width:"80%"}}/>
                </div>
                <div class="column">
                    <h2 style={{marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%"}}>
                        <strong>USAGE OF WACO GUIDE</strong>
                    </h2>
                    <p>
                    Content published in The Waco Guide, including but not limited to decision support tools, color schema, mapping, and flow charts, is owned and copyrighted by Heart of Texas Community Health Center and may not be copied, reproduced, distributed, or transmitted in any form without prior written permission of the Heart of Texas Community Health Center and without express attribution to the Heart of Texas Community Health Center.
                    </p>
                    <p>
                    Notwithstanding the above, the Heart of Texas Community Health Center provides a limited license to you to copy and/or distribute content published in The Waco Guide to others for scholarly or academic teaching purposes. In such event, you must expressly attribute the Heart of Texas Community Health Center as the copyright owner, The Waco Guide to Psychopharmacology in Primary Care® as the source, and the page number(s) of the copied and distributed content.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default Usage