import React, { Component, createRef, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import Page from '../../components/page';
import viewImg from '../../assets/images/file.png';
import diagImg from '../../assets/images/bc_diagnosis.png';
import diagImgGray from '../../assets/images/bc_diagnosis_gray.png';
import medImg from '../../assets/images/bc_medication.png';
import medImgGray from '../../assets/images/bc_medication_gray.png';
import recImg from '../../assets/images/bc_recommendation.png';
import recImgGray from '../../assets/images/bc_recommendation_gray.png';
import waco from '../../service/waco';
import Footer from '../../components/footer';
import "./app.css";
import "./steps.css";
import FLoatingPDF from '../components/floatingPDF';
const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  let algorithms = require("../../data/algorithms.json");

class Management extends Component {

    constructor(props) {
        super(props);
        this.childRef = React.createRef();

        this.state = {
            location:this.props.locationData,
            page: {},
            diagnoses: [],
            facts: {},
            state: "intro",
            currentAlgorithm:null,
            currentDiagnosis:"",
            currentComorbid:"",
            hidePDF:"hidden",
            pdf:"",
            isCalc:false
        };

        this.setVariable = this.setVariable.bind(this);
        this.getVariable = this.getVariable.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
        this.goToPrevPage = this.goToPrevPage.bind(this);
        this.nextPageDelay = this.nextPageDelay.bind(this);
        this.togglePDF = this.togglePDF.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        
        if(this.state.location != null) {
            this.state.page = this.state.location;
            this.nextPageDelay(); // Using a timer delay so the page loads before calling goToNextPage
        }

        /*
        if(new URLSearchParams(this.state.location.search).get('name') != null) {
            this.setVariable("diagnosis", new URLSearchParams(this.state.location.search).get('name'))
        }
        */
    }

    checkMedCount(data) {
        let origVal = waco.getVariable("medication_history");
        waco.setVariable("medication_history", "Yes");
        data = waco.reevaluate("medication");
        var visibleMedCount = 0
        for(var i=0; i<data.content.length;i++) {
            if(data.content[i].type == "switch") {
                visibleMedCount++;
            }
        }
        if(origVal == "") {
            origVal = "No";
        }
        waco.setVariable("medication_history", origVal);
        return visibleMedCount;
    }

    nextPageDelay = async event=> { // After delay, loads the next page
        await delay(1);
        this.goToNextPage();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.algo !== this.props.algo) {
            this.loadIt(this.props.algo);
        }
    }

    contextData() {
        var url = new URL(window.location.href);
        var c = url.searchParams.get("context");
        if (c) {
            var datas = c.split('.');
            if (datas.length > 2) {
                var params = atob(datas[1]);
                var data = JSON.parse(params);
                return {context: c, data: data};
            }
        }
        return null;
    }

    
    loadIt(algo) {
        if (!algo) {
            return;
        }

        let page = this.state.page;
        this.state.currentAlgorithm = algo;

        var json = require('../../data/' + algo.algorithm + '.json');

        var diagnosis = algo.diagnosis;
        var age = algo.age;
        var comorbidities = algo.population;
        var partum = algo.pregnancy;

        if(comorbidities == "Geriatric") {
            age = comorbidities;
            comorbidities = "";
        }
        if(partum == "") {
            partum = "None";
        } else {
            comorbidities = "childbearing"
        }
        comorbidities = comorbidities.toLowerCase();
        
        waco.setAlgorithm(json);
        waco.setVariable('comorbidities', comorbidities);
        waco.setVariable('patient_age', age);
        waco.setVariable('partum', partum);
        waco.setVariable("diagnosis", diagnosis);

        this.state.currentDiagnosis = diagnosis;

        if(partum != "None" && partum != null) {
            this.state.currentComorbid = partum 
        } else if(comorbidities != "") {
            this.state.currentComorbid = comorbidities.charAt(0).toUpperCase() + comorbidities.slice(1); 
        } else {
            this.state.currentComorbid = age 
        }
        this.state.pdf = algo.pdf;

        var data = waco.reevaluate("intro");
        data.state = "intro";
        this.setState({ page: data, state: "intro" });
        
        if(data.content.length == 0)  {
            var data = waco.reevaluate("medication");
            data.state = "medication";
            if(this.checkMedCount(data) > 1) {
                this.setState({ page: data, state: "medication" });
            } else {
                var data = waco.reevaluate("recommendation");
                data.state = "recommendation";
                this.setState({ page: data, state: "recommendation" });
            }
        }

        console.log(window.location.href);
        var cx = this.contextData();
        if (cx) {
            this.fetchAndSetContext(cx);
        }
    }

    setFacts = (facts) => {
        var vars = Object.keys(facts);
        var medHist = false;
        for(var i=0;i<vars.length;i+=1) {
            var fid = vars[i];
            var fact = facts[fid];
            if (fact !== null) {
                var fvalue = fact.value;
                waco.setVariable(fid, fvalue);
                if(fid.includes("medication_") && !medHist) {
                    console.log('test id ' + fid + "   value  " + fvalue)
                    waco.setVariable("medication_history", "Yes");
                }
            }
        }
    }

    fetchAndSetContext = (cx) => {
        fetch('https://api.clinicalalgorithms.com/v1/data/' + cx.data.source, { 
            method: 'GET', 
            mode: 'cors', // no-cors
            headers: new Headers({
                'Authorization': 'Bearer ' + cx.context, 
                'Accept': 'application/json'
            }), 
        }).then( response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then(json => {
            
            this.setFacts(json.facts);
            var data = waco.reevaluate("intro");
            data.state = "intro";
            console.log(data);
            this.setState({ page: data, state: "intro", facts: json.facts });
        });
    }

    rebuildDiagnosisVariables = () => {
        let diagnosisName = this.getVariable("diagnosis");

        if(diagnosisName == null || diagnosisName == "Select") {
            return;
        }

        let age = this.getVariable("patient_age");
        let comorbidities = this.getVariable("comorbidities");
        var partum = this.getVariable("partum") || "None";

        let diagnosis = this.state.diagnoses.find((a) => a.name == diagnosisName);
        let women_category = diagnosis.category.indexOf("Women") >= 0;
        
        waco.setVariable("adult_enabled", true);
        let pediatric_enabled = diagnosis.category.indexOf("Pediatric") >= 0;
        let adolescent_enabled = diagnosis.category.indexOf("Adolescent") >= 0;
        let geriatric_enabled = diagnosis.comorbidities.indexOf("Geriatric") >= 0;
        waco.setVariable("geriatric_enabled", geriatric_enabled);
        
        if(adolescent_enabled && !pediatric_enabled) {
          waco.setVariable("pediatric_enabled", true);
          waco.setVariable("adolescent_enabled", adolescent_enabled);
          waco.setVariable("sub_enabled", false);
          waco.setVariable("sub_age", "Adolescent");
        } else {
          waco.setVariable("pediatric_enabled", pediatric_enabled);
          waco.setVariable("adolescent_enabled", adolescent_enabled);
        }
        let cardiac_enabled = diagnosis.comorbidities.indexOf("Cardiac") >= 0;
        let hepatic_enabled = diagnosis.comorbidities.indexOf("Hepatic") >= 0;
        let renal_enabled = diagnosis.comorbidities.indexOf("Renal") >= 0;
        let obesity_enabled = diagnosis.comorbidities.indexOf("Obesity") >= 0;
        waco.setVariable("cardiac_enabled", cardiac_enabled);
        waco.setVariable("hepatic_enabled", hepatic_enabled);
        waco.setVariable("renal_enabled", renal_enabled);
        waco.setVariable("obesity_enabled", obesity_enabled);
        waco.setVariable("comorbidities_enabled", cardiac_enabled);
        

        if(age == "Adult" && diagnosis.category.indexOf("Adult") == -1) {
            if(women_category) {
                waco.setVariable("comorbidities", "childbearing");
            } else {
                waco.setVariable("comorbidities", "cardiac");
            }
        }

        if (women_category) {
          if (!diagnosis.pregnancy.indexOf(partum)) {
            partum = "None";
          }
          waco.setVariable("reproductive_active", true);
          waco.setVariable("childbearing_enabled", true);
          let preconception_enabled = diagnosis.pregnancy.indexOf("Preconception") >= 0;
          let antepartum_enabled = diagnosis.pregnancy.indexOf("Antepartum") >= 0;
          let postpartum_enabled = diagnosis.pregnancy.indexOf("Postpartum") >= 0;
          waco.setVariable("preconception_enabled", preconception_enabled);
          waco.setVariable("antepartum_enabled", antepartum_enabled);
          waco.setVariable("postpartum_enabled", postpartum_enabled);
          if (partum == "None") {
            if (preconception_enabled) {
              partum = "Preconception";
            } else if (antepartum_enabled) {
              partum = "Antepartum";
            } else if (postpartum_enabled) {
              partum = "Postpartum";
            }
          }
          waco.setVariable("partum", partum);
        } else {
          waco.setVariable("reproductive_active", false);
          waco.setVariable("childbearing_enabled", false);
          waco.setVariable("preconception_enabled", false);
          waco.setVariable("antepartum_enabled", false);
          waco.setVariable("postpartum_enabled", false);
          if (comorbidities == "childbearing") {
            waco.setVariable("comorbidities", "");
            waco.setVariable("comorbidities_childbearing", false);
          }
        }
        if (diagnosis.category.indexOf("Adult") == -1) {
          if (diagnosis.category.indexOf("Women") >= 0) {
            waco.setVariable("comorbidities","childbearing")
            waco.setVariable("patient_age","Adult")
          } else if (diagnosis.comorbidities.length > 0) {
            if (diagnosis.comorbidities[0] != "Geriatric") {
              waco.setVariable("comorbidities", diagnosis.comorbidities[0]);
              waco.setVariable("patient_age", "Adult");
            } else {
              waco.setVariable("adult_enabled", false);
            }
          } else {
            waco.setVariable("adult_enabled", false);
          }
        }
    
        let patient_age = waco.getVariable("patient_age") || "Adult";
        if (
          patient_age == "Geriatric" ||
          patient_age == "Pediatric" ||
          patient_age == "Adolescent" ||
          comorbidities != "childbearing"
        ) {
          waco.setVariable("partum", "None");
        }
    
        for(var i=0;i<algorithms.length;i++) {
            if(comorbidities != "" || partum != "None") {
    
                if(comorbidities == "childbearing"){
                    if(partum == "None") {
                        this.state.pdf = "none";
                        this.state.currentAlgorithm = algorithms[i];
                    }
                    if(algorithms[i].diagnosis == diagnosis.name && (algorithms[i].population == partum)) {
                        this.state.pdf = algorithms[i].pdf;
                        this.state.currentAlgorithm = algorithms[i];
                    }
                } else {
                    waco.setVariable("partum", "None");
                    if(algorithms[i].diagnosis == diagnosis.name && (algorithms[i].population.toLowerCase() == comorbidities)) {
                        this.state.pdf = algorithms[i].pdf;
                        this.state.currentAlgorithm = algorithms[i];
                    }
                }
            } 
            else {
                if(algorithms[i].diagnosis == diagnosis.name && algorithms[i].population == patient_age) {
                    this.state.pdf = algorithms[i].pdf;
                    this.state.currentAlgorithm = algorithms[i];
                }
            }
        }
      };

    setVariable = (id, value) => {
        waco.setVariable(id, value);
        // reset patient_age and sub_age if we change the diagnosis
        if(id == "diagnosis") {
            waco.setVariable("_patient_age", "");
            waco.setVariable("sub_age", "");
        }
        if (this.state.page.title == "Diagnosis") {
            this.rebuildDiagnosisVariables();
          }

        var data = waco.reevaluate(this.state.state);
        data.state = this.state.state;
        this.setState({ page: data });
    }

    getVariable = (id) => {
        return waco.getVariable(id);
    }

    goToNextPage = () => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
          });

        let page = this.state.page;
        let symptoms = page.variables.symptoms;
        let medCount = page.variables.medCount;

        if (page.title == "Diagnosis") {

            let diagnosis = page.content.find(c => c.id == "diagnosis");
            let age = this.getVariable("patient_age");
            let comorbidities = this.getVariable("comorbidities");
            let partum = page.content.find(c => c.id == "partum");

            this.state.currentDiagnosis = diagnosis.value;
            if(partum.value != "None" && partum.value != null) {
                this.state.currentComorbid = partum.value 
            } else if(comorbidities != "") {
                this.state.currentComorbid = comorbidities.charAt(0).toUpperCase() + comorbidities.slice(1); 
            } else {
                this.state.currentComorbid = age 
            }

            for(var i=0;i<algorithms.length;i++) {
                if(partum.value != "None" && partum.value != null) {
                    if(algorithms[i].diagnosis == diagnosis.value && (algorithms[i].population == partum)) {
                        this.state.pdf = algorithms[i].pdf;
                        this.state.currentAlgorithm = algorithms[i];
                        break;
                    } 
                } 
                else if(comorbidities != "") {
                    if(algorithms[i].diagnosis == diagnosis.value && (algorithms[i].population.toLowerCase() == comorbidities)) {
                        this.state.pdf = algorithms[i].pdf;
                        this.state.currentAlgorithm = algorithms[i];
                        break;
                    }
                }
                else {
                    if(algorithms[i].diagnosis == diagnosis.value && algorithms[i].population == age) {
                        this.state.pdf = algorithms[i].pdf;
                        this.state.currentAlgorithm = algorithms[i];
                        break;
                    }
                }
            }

            let fullDiagnosis = this.state.diagnoses.find(d => d.title === diagnosis.value);
            if (!fullDiagnosis) {
                return;
            }

            var json = require('../../data/' + fullDiagnosis.filename + '.json');
            waco.setAlgorithm(json);            

            var facts = this.state.facts || {};
            this.setFacts(facts);
            
            var data = waco.reevaluate("intro");
            let idx = data.content.length;
            data.state = nextState;
            this.setState({ page: data, state: "intro" });

            if(idx > 0) {
                this.state.state = "intro";
                data.state = nextState;
                this.setState({ page: data, state: "intro" });
            } else {
                if(this.checkMedCount(data) <= 1 || symptoms == "unmedicated" || symptoms == "success" || medCount == 0) {
                    nextState = "recommendation";
                } else {
                    nextState = "medication";
                }
                data = waco.reevaluate(nextState);
                data.state = nextState;
                this.setState({ page: data, state: nextState });
            }


        } else {

            if(this.state.state == "medication") {
                nextState = "recommendation";
                var data = waco.reevaluate(nextState);
                data.state = nextState;
                this.setState({ page: data, state: nextState });
                if(data.figure != null) {
                    this.state.pdf =  data.figure[0].split("_")[0];
                }
                return;
            }

            var nextState = this.state.state;



            if(this.state.state != "medication") {
                if(this.checkMedCount(data) <= 1 || symptoms == "unmedicated" || symptoms == "success" || medCount == 0) {
                    nextState = "recommendation";
                } else {
                    nextState = "medication";
                }
            } else {
                nextState = "recommendation";
            }

            var data = waco.reevaluate(nextState);
            data.state = nextState;
            if(data.figure != null) {
                this.state.pdf =  data.figure[0].split("_")[0];
            }
            this.setState({ page: data, state: nextState });
        }
    }

    goToPrevPage = () => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
          });
          
        let page = this.state.page;
        let symptoms = page.variables.symptoms;
        let medCount = page.variables.medCount;
        
        if(page.state == "recommendation" && this.state.currentAlgorithm != null) {
            this.state.pdf = this.state.currentAlgorithm.pdf;
        }

        if (page.title == "Diagnosis") {

            let diagnosis = page.content.find(c => c.id == "diagnosis");
            let age = this.getVariable("patient_age");
            let comorbidities = this.getVariable("comorbidities");
            let partum = page.content.find(c => c.id == "partum");
            
            this.state.currentDiagnosis = diagnosis.value;
            if(partum.value != "None" && partum.value != null) {
                this.state.currentComorbid = partum.value 
            } else if(comorbidities != "") {
                this.state.currentComorbid = comorbidities.charAt(0).toUpperCase() + comorbidities.slice(1); 
            } else {
                this.state.currentComorbid = age 
            }

            for(var i=0;i<algorithms.length;i++) {
                if(partum.value != "None" && partum.value != null) {
                    if(algorithms[i].diagnosis == diagnosis.value && (algorithms[i].population == partum)) {
                        this.state.pdf = algorithms[i].pdf;
                        break;
                    } 
                } 
                else if(comorbidities != "") {
                    if(algorithms[i].diagnosis == diagnosis.value && (algorithms[i].population.toLowerCase() == comorbidities)) {
                        this.state.pdf = algorithms[i].pdf;
                    }
                }
                else {
                    if(algorithms[i].diagnosis == diagnosis.value && algorithms[i].population == age) {
                        this.state.pdf = algorithms[i].pdf;
                    }
                }
            }

            let fullDiagnosis = this.state.diagnoses.find(d => d.title === diagnosis.value);
            if (!fullDiagnosis) {
                return;
            }

            var json = require('../../data/' + fullDiagnosis.filename + '.json');
            waco.setAlgorithm(json);

            var data = waco.reevaluate("intro");
            let idx = data.content.length;

            data.state = nextState;
            this.setState({ page: data, state: "intro", facts: {} });

            if(idx > 0) {
                this.state.state = "intro";
                data.state = nextState;
                this.setState({ page: data, state: "intro", facts: {} });
            } else {
                if(this.checkMedCount(data) <= 1 || symptoms == "unmedicated" || symptoms == "success" || medCount == 0) {
                    nextState = "recommendation";
                } else {
                    nextState = "medication";
                }

                data = waco.reevaluate(nextState);
                data.state = nextState;
                this.setState({ page: data, state: nextState });
            }


        } else {
            let symptoms = page.variables.symptoms
            if(this.state.state == "intro") {
                this.reloadPage();
                window.location.reload(false);
                return(<Navigate to="/app/"/>)
            }
            else if(this.state.state == "medication") {
                nextState = "intro";
                var data = waco.reevaluate(nextState);
                data.state = nextState;
                this.setState({ page: data, state: nextState });
                var data = waco.reevaluate("intro");
                let idx = data.content.length;
    
                data.state = nextState;
                this.setState({ page: data, state: "intro", facts: {} });
    
                if(idx == 0) {
                    this.reloadPage();
                    window.location.reload(false);
                    return(<Navigate to="/app/"/>)
                }

                return;
            } else if(this.state.state == "recommendation" ) {
                nextState = "medication";
                var data = waco.reevaluate(nextState);
                data.state = nextState;
                if(this.checkMedCount(data) <= 1 || symptoms == "unmedicated" || symptoms == "success" || medCount == 0) {
                    nextState = "intro";
                    var data = waco.reevaluate(nextState);
                    data.state = nextState;
                    if(data.content.length > 0){
                        this.setState({ page: data, state: nextState });
                    } else {
                        this.reloadPage();
                        return(<Navigate to="/app/"/>)
                    }
                    return;
                } else {
                    this.setState({ page: data, state: nextState });
                }
                return;
            }

            var nextState = this.state.state;

            if(this.state.state != "medication") {
                if(this.checkMedCount(data) <= 1 || symptoms == "unmedicated" || symptoms == "success" || medCount == 0) {
                    nextState = "recommendation";
                } else {
                    nextState = "medication";
                }
            } else {
                nextState = "recommendation";
            }

            var data = waco.reevaluate(nextState);
            data.state = nextState;
            this.setState({ page: data, state: nextState });
        }
    }

    togglePDF = (pdfName) => {
        if(pdfName != null && typeof(pdfName) == "string") {
            this.setState({pdf:pdfName})
        }
        if(this.state.hidePDF == "visible") {
            this.setState(state =>({
                hidePDF: "hidden"
            }))
        } else {
            this.setState(state =>({
                hidePDF: "visible"
            }))
        }
    }

    render() {
        let diagnosisClass = (this.state.state == "intro" || this.state.state == "medication" || this.state.state == "recommendation") ? "current " + this.state.state : "";
        let medicationClass = (this.state.state == "medication" || this.state.state == "recommendation") ? "current " + (this.state.state == "recommendation" ? "recommendation" : "") : "";
        let recommendationClass = this.state.state == "recommendation" ? "current" : "";

        let diagnosisImg = (this.state.state == "intro" || this.state.state == "medication" || this.state.state == "recommendation") ? diagImg : diagImgGray;
        let medicationImg = (this.state.state == "medication" || this.state.state == "recommendation") ? medImg : medImgGray;
        let recommendationImg = this.state.state == "recommendation" ? recImg : recImgGray;

        let medHistory = this.getVariable("medication_history");
        var diagnosisTitle = "";
        diagnosisTitle = this.state.currentComorbid + " " + this.state.currentDiagnosis;

        if(!this.state.isCalc) {
            return (
                <div style={{position:"relative", float:"center"}}>
                    <div className="App">
                        <header className="Diagnosis-header">
                            {(this.state.currentDiagnosis != "") ? <div style={{height:"60px", textAlign:"center", visibility:((diagnosisTitle == "") ? "hidden" : "visible")}}>
                                <button className='btnTitle desktop' style={{height:"100%", color:"black", fontSize:"22pt", fontFamily:"futura-pt, Helvetica,arial,sans-serif"}} onClick={e => this.togglePDF(this.state.currentAlgorithm.pdf)}><b>{diagnosisTitle}</b><img style={{paddingLeft:"15px", height:"60%"}} src={viewImg}/></button>
                                <a href={'/assets/' + this.state.currentAlgorithm.pdf + '.pdf'} target="_blank" className='btnTitle mobile' style={{height:"100%", color:"black", fontSize:"16pt", fontFamily:"futura-pt, Helvetica,arial,sans-serif"}} ><b>{diagnosisTitle}</b><img style={{paddingLeft:"15px", height:"60%"}} src={viewImg}/></a>
                            </div> : ""
                            }
                            <ol className="tool-step-img">
                                <li><img style={{maxWidth:"55%", minWidth:"40px"}} src={diagnosisImg}/></li>
                                <li><img style={{maxWidth:"55%", minWidth:"40px"}} src={medicationImg}/></li>
                                <li><img style={{maxWidth:"55%", minWidth:"40px"}} src={recommendationImg}/></li>
                            </ol>
                            <ol id="steps" className="tool-step-text cd-multi-steps text-bottom count"> 
                                <li id="s1" className={diagnosisClass}><em>Diagnosis</em></li>
                                <li id="s2" className={medicationClass}><em>Medication</em></li>
                                <li id="s3" className={recommendationClass}><em>Recommendation</em></li>
                            </ol>
                        </header>
                        <Page
                            page={this.state.page}
                            state={this.state.state}
                            diagnoses={this.state.diagnoses}
                            facts={this.state.facts}
                            setVariable={this.setVariable}
                            getVariable={this.getVariable}
                            goToNextPage={this.goToNextPage}
                            goToPrevPage={this.goToPrevPage}
                            togglePDF={this.togglePDF}
                            algorithm={this.state.currentAlgorithm} />
                    </div>
                    <Footer />
                    <FLoatingPDF pdfName={this.state.pdf} isHidden={this.state.hidePDF} togglePDF={this.togglePDF}/>
                </div>
            )
        } else {
            return (
                <div style={{overflow:"auto"}}>
                    <div className="App">
                        <Page
                            page={this.state.page}
                            state={this.state.state}
                            diagnoses={this.state.diagnoses}
                            setVariable={this.setVariable}
                            getVariable={this.getVariable}
                            goToNextPage={this.goToNextPage}
                            goToPrevPage={this.goToPrevPage}
                            togglePDF={this.togglePDF} 
                            algorithm={this.state.currentAlgorithm}
                            setcalResult={this.props.setcalResult}/>
                    </div>
                </div>
            )
        }
    }

    reloadPage = () => {
        waco.startOver();
        this.state.currentDiagnosis = "";
        var diagnoses = require('../../data/diagnoses.json');
        var json = require('../../data/global.json');
        console.log('g2');

        waco.setAlgorithm(json);
        var data = waco.reevaluate("intro");
        data.state = "intro";
        this.setState({ page: data, diagnoses: diagnoses, state: "intro", facts: {} });
    }

    componentDidMount() {
        if(this.props.algo == null || (typeof this.props.algo) != "string") {
            this.state.isCalc = false;
            var diagnoses = require('../../data/diagnoses.json');
            var json = require('../../data/global.json');
            waco.setAlgorithm(json);

            var cx = this.contextData();
            if (cx) {
                var data = cx.data;
                if (data.context && data.context.diagnosis) {
                    diagnoses = diagnoses.filter( d => d.title == data.context.diagnosis);
                    waco.setVariable("diagnosis", data.context.diagnosis);
                }
                this.fetchAndSetContext(cx);
            }

            data = waco.reevaluate("intro");
            data.state = "intro";
            console.log(data);
            this.setState({ page: data, diagnoses: diagnoses, state: "intro", facts: {} });
            
            if (!this.props.algo) {
                return;
            }

            this.loadIt(this.props.algo);
        } else {
            this.state.isCalc = true;
            var diagnoses = require('../../data/diagnoses.json');
            var json = require('../../data/' + this.props.algo + '.json');
            waco.setAlgorithm(json);
            var data = waco.reevaluate("intro");
            data.state = "intro";
            console.log(data);
            this.setState({ page: data, diagnoses: diagnoses, state: "intro", facts: {} });
        }

    }

    componentWillUnmount() {
        waco.startOver();
    }
}

export default Management;
