import React, { useState } from 'react'
import { findDOMNode } from 'react-dom';
import plusImg from "../assets/images/plusBtn.png"
import minusImg from "../assets/images/minusBtn.png"
import cautionImg from "../assets/images/caution.png"
import warningImg from "../assets/images/warning.png"
import tallMan from "../service/tallMan.js"

function insertHref(content) {
    var startIndex=content.lastIndexOf("https");
    var endIndex=content.lastIndexOf("html")+4;
    if (endIndex > 3) {
        var str = content.substring(0,startIndex)+'<a href="'+content.substring(startIndex, endIndex)+'">'+content.substring(startIndex, endIndex)+'</a>'+content.substring(endIndex);
        return (str)
    }
    return content;
}

function htmlAsDiv(content) {
    var html = content.replace(/\r?\n/g, '<br />');
    return (<div dangerouslySetInnerHTML={{ __html: html}} />);
}

const MedicationIos = ({ medication, showClass, showBranch = false, forceExpand = false, index }) => {

    const plus = plusImg
    const minus = minusImg
    const expandImg = { plus, minus }
    const [selected, setSelected] = useState(expandImg.plus)
    const [isExpanded, setExpanded] = useState(false);
    
    function getImageName(e) {
        if (e && e.target && e.target.href) {
            window.openURL(e.target.href);
        }
        if(e != null) {
            var child = document.getElementById(medication.name + index);
            setExpanded((e.target.getAttribute("aria-expanded") === 'true'));
            let exp = e.target.getAttribute("aria-expanded") === 'true'
            if(exp) {
                child.parentElement.classList.add('medExpanded');
                setSelected(expandImg.minus);
                return minusImg;
            } else if(!exp){
                child.parentElement.classList.remove('medExpanded');
                setSelected(expandImg.plus);
                return plusImg;
            }
        }
        setSelected(expandImg.plus)
        return plusImg;
    }

    let section = "collapseMed" + index;
    let hsection = "#" + section;
    let emoji = medication.alert =="caution" ? cautionImg : medication.alert == "warning" ? warningImg : "";
    let gridStyle = (emoji != "" ? "35px auto 1fr 0fr" : "auto 1fr 0fr")
    let dataToggle = forceExpand == false ? "collapse" : ""

    return (<React.Fragment>
        <div key={medication.name} id={(medication.name + index)} className={"med-card med-body odd"}>
            <div className={''  + (isExpanded ? "medExpanded" : "")} style={{display:"grid", gridTemplateColumns:gridStyle, paddingLeft:"10px"}} data-toggle={dataToggle} data-bs-toggle={dataToggle} href={hsection} role="button" aria-expanded={forceExpand} aria-controls={section} onClick={e => getImageName(e)}>
                {emoji != "" ? (
                                <div className='columnMeds'>
                                    <img style={{width:"100%", float:"left"}} src={emoji}/>
                                </div>) : 
                                ("")
                }
                
                <div className='columnMeds'>
                    <b>{tallMan.getTallMed(medication.name)} {medication.branch && medication.branch.length && showBranch > 0 ? (
                        "(" + medication.branch + ")"
                ) : null}</b>
                </div>
                <div className='columnMeds' style={{maxWidth:"440px", fontSize:"1em", whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>
                        {medication.class && medication.class.length > 0 ? (
                        <span style={{width:"100%"}}>{showClass ? <b>Class:</b>:<b></b>} <i style={{fontSize:"1em", whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis", width:"100%"}}>{medication.class}</i></span>
                ) : null}
                </div>
                <div className='column10'>
                    {forceExpand == false ? <img style={{width:"50%", float:"right"}} src={selected}/> : ""}
                </div>
            </div>
            <div className={"card0 card0-body odd ignoreClick"}>
            <div className={dataToggle} id={section}>
            <div style={{width:"100%", height:"1px", backgroundColor:"#7a7a7a", marginBottom:"10px"}}></div>
            <div className="row medrow" style={{paddingBottom:"15px"}}>
                {medication.class && medication.class.length > 0 ? (<div>
                        <table style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <td style={{width:"50%", padding: "0px 10px 10px 10px"}}>
                                    <br /><b>Class: </b>{medication.class}<br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    ) : null}
                    {medication.brands && medication.brands.length > 0 ? (<div>
                        <table style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <td style={{width:"50%", padding: "0px 10px 10px 10px"}}>
                                    <br /><b>Brands: </b>{medication.brands}<br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    ) : null}
                    {medication.branch && medication.branch.length > 0 ? (<div>
                        <table style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <td style={{width:"50%", padding: "0px 5px 5px 10px;"}}>
                                    <br /><b>Branch: </b>{medication.branch}<br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    ) : null}
                {medication.dosing && medication.dosing.length > 0 ? (<div  align="center">
                    <table style={{width:"100%"}}>
                        <tbody>
                            <tr>
                                <td style={{width:"50%", padding: "20px", background: "#fff1cf"}}>
                                <br /><b>{htmlAsDiv(medication.dosing)}</b><br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                ) : null}
                <div align="center">
                    <table style={{width:"100%"}}>
                        <tbody>
                            <tr>
                                <td style={{width:"50%", padding: "20px", verticalAlign:"top"}}>
                                    {medication.side_effects_common && medication.side_effects_common.length > 0 ? (<div>
                                    <b>Side effects (common):</b> {htmlAsDiv(medication.side_effects_common)}
                                    </div>
                                    ) : null}
                                </td>
                                <td style={{width:"50%", padding: "20px", verticalAlign:"top"}}>
                                    {medication.side_effects_rare && medication.side_effects_rare.length > 0 ? (<div>
                                        <b>Side effects (rare/serious):</b> {htmlAsDiv(medication.side_effects_rare)}
                                    </div>
                                    ) : null}
                                </td>
                            </tr>
                        </tbody>
                    </table></div>
               {medication.notes && medication.notes.length > 0 ? (<div style={{width:"100%"}}>
                    <br /><b>Notes:</b> {htmlAsDiv(medication.notes)}
                </div>
                ) : null}
                {medication.monitor && medication.monitor.length > 0 ? (<div style={{width:"100%"}}>
                     <br /><b>Monitor:</b> {htmlAsDiv(medication.monitor)}
                 </div>
                 ) : null}
                 {medication.info && medication.info.length > 0 ? (<div style={{width:"100%"}}>
                      <br /><b>Additional Info:</b> {htmlAsDiv(insertHref(medication.info))}
                  </div>
                  ) : null}
            </div>
        </div>
        </div>
        </div>
        
    </React.Fragment>);
};

export default MedicationIos