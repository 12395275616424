import React, { Component } from 'react';
import Page from '../../components/page';
import Footer from '../../components/footer';
import waco from '../../service/waco';

import { Outlet } from 'react-router-dom';

class Home extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>

                <div className="App">
                    <header className="App-header">
                    </header>
                </div>

                <section>
                    <div className="container">
                        <Outlet />
                    </div>
                </section>

            </div>
        )
    }

}

export default Home;
