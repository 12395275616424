import React, { useRef, useState } from 'react'
import SegmentedControl from "../pages/components/SegmentedControl";

var age;
var serum;
var gender = "Male";

const CKD = ({ content, setCKD }) => {

    let changeFilter = (val) => {
        gender = val;
        calculateChange();
    }

    let changeAge = (event) => {
        age = Math.floor(parseFloat(event.target.value))
        calculateChange();
    }

    let changeSerum = (event) => {
        serum = parseFloat(event.target.value)
        calculateChange();
    }

    let calculateChange = () => {
        if(age!=null && serum != null) {
            var ckd = 0
            if(gender == "Female") {
                if(serum<=0.7) {
                    ckd = Math.ceil(142*Math.pow((serum/0.7), -0.241)*Math.pow(0.9938, age)*1.012)
                } else {
                    ckd = Math.ceil(142*Math.pow((serum/0.7), -1.2)*Math.pow(0.9938, age)*1.012)
                }
            } else {
                if(serum<=0.7) {
                    ckd = Math.ceil(142*Math.pow((serum/0.9), -0.302)*Math.pow(0.9938, age))
                } else {
                    ckd = Math.ceil(142*Math.pow((serum/0.9), -1.2)*Math.pow(0.9938, age))
                }
            }
            setCKD(content.id, ckd)
        }
    }

    return (
        <div className={"card"} style={{padding:"20px"}}>
            <div className='grid-container' style={{gridTemplateColumns:"repeat(auto-fit, minmax(200px, 48%))"}}>
                <div>
                <h3 style={{padding:"0"}}>Sex</h3>
                <SegmentedControl
                    name="group-1"
                    callback={(val) => changeFilter(val)}
                    controlRef={useRef()}
                    className="controls-container-sm"
                    subClassName={"controls-sm"}
                    segmentClassName="segment-sm"
                    margin="0"
                    segments={[
                        {
                        label: "Male",
                        value: "Male",
                        ref: useRef()
                        },
                        {
                        label: "Female",
                        value: "Female",
                        ref: useRef()
                        }
                    ]}
                    />
                </div>
                <div>
                    <h3 style={{padding:"0"}}>Age</h3>
                    <input className='form-control contact contact-input' type="number" placeholder='' onChange={changeAge} />
                </div>
            </div>
            <div style={{paddingTop:"30px", paddingLeft:"10px"}}>
                <h3 style={{padding:"0"}}>Serum creatinine mg/dL</h3>
                <input style={{width:"50%"}} className='form-control contact contact-input' type="number" placeholder='' onChange={changeSerum} />
            </div>
        </div>)
};

export default CKD