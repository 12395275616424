import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './pages/management';
import Tools from './pages/tools';
import CompTools from './pages/tools/compTool';

import Home from './pages/home';
import Welcome from './pages/home/welcome';
import Team from './pages/home/team';
import Residency from './pages/home/residency';
import AboutGuide from './pages/home/about';
import Talks from './pages/home/talks';
import Methodology from './pages/home/methodology';
import FAQ from './pages/home/faq';
import Conference from './pages/home/conference';
import Presentation from './pages/home/presentation';
import News from './pages/home/news';
import Usage from './pages/home/usage';
import Privacy from './pages/home/privacy';
import MobileApp from './pages/home/mobileapp';
import Contact from './pages/home/contact';

import Nav from './nav';
import {
    BrowserRouter, Route, Navigate, Routes, Outlet, Link,
    useParams
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';

import title from './assets/images/title.png';
import titleHor from './assets/images/logo_horizontal_black.png';
import facebook from './assets/images/facebook.png';
import twitter from './assets/images/twitter.png';
import instagram from './assets/images/insta.png';
import SubstanceUseDisorders from './pages/home/substanceUseDisorders';
import MobileNav from './pages/components/mobileNav'
import ScrollToTop from "./pages/components/scrollToTop";

import { pdfjs } from 'react-pdf';
export const getWorker = () => {
    try {
        return require('react-pdf/node_modules/pdfjs-dist/legacy/build/pdf.worker.entry.js')
    } catch (ex) {
        return require('pdfjs-dist/legacy/build/pdf.worker.entry.js')
    }
}
pdfjs.GlobalWorkerOptions.workerSrc = getWorker();

let algorithms = require('./data/algorithms.json');

function link_safe(algorithm) {
    algorithm = algorithm.replace(/\,/g, '');
    algorithm = algorithm.replace(/\s+/g, '-');
    algorithm = algorithm.replace(/\//g, '-');
    return algorithm.toLowerCase();
}

function Header() {
    let location = useLocation();
    if (location.pathname == "/talks") {
        return (
            <div className="" id="main-nav">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{ position: "relative" }}>
                            <div className="column30 text-left desktop" style={{ width: "20%", float: "right" }}>
                                <Link to="./">
                                    <h4>
                                        <img src={title} className="title-logo" />
                                    </h4>
                                </Link>
                            </div>
                            <div className="column30 text-left mobile" style={{ width: "230px", paddingTop: "18px" }}>
                                <Link to="./">
                                    <h4 className="">
                                        <img src={titleHor} />
                                    </h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <section className="header-area">
            <div className="navbar-area" id="main-nav">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{ position: "relative" }}>
                            <div className="column30 text-left desktop" style={{ width: "20%" }}>
                                <Link to="./">
                                    <h4>
                                        <img src={title} className="title-logo" />
                                    </h4>
                                </Link>
                            </div>
                            <div className="column30 text-left mobile" style={{ width: "230px", paddingTop: "18px" }}>
                                <Link to="./">
                                    <h4 className="">
                                        <img src={titleHor} />
                                    </h4>
                                </Link>
                            </div>
                            <MobileNav />
                            <div className="desktop">
                                <nav className="navbar navbar-expand navbar-light navbar-toggle collapsed">
                                    <div className="collapse navbar-collapse" id="navbarsExample02">
                                        <ul className="navbar-nav ml-5">
                                            <li className="nav-item">
                                                <Link className="dropdown-item" to="/about-guide">About</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="dropdown-item" to="/methodology">Methodology</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="dropdown-item" to="/theteam">The Team</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="dropdown-item" to="/news">News</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="dropdown-item" to="/conference">Conference</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="dropdown-item" to="/contact-us">Contact Us</Link>
                                            </li>
                                        </ul>
                                        <div>
                                            <a className="social-icon" href='https://www.facebook.com/profile.php?id=100067715317391' target="_blank"><img src={facebook} /></a>
                                            <a className="social-icon" href='https://mobile.twitter.com/wacoguide' target="_blank"><img src={twitter} /></a>
                                            <a className="social-icon" href='https://www.instagram.com/wacoguide/' target="_blank"><img src={instagram} /></a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
}

const routes = (
    <div>
        <BrowserRouter>

            <ScrollToTop />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

            <Header />

            <Routes>
                <Route path="/app/*" element={<AppLoad />} />
                <Route path="/all-tools" element={<CompTools category={"all-tools"} />} />
                <Route path="/additional-resources" element={<CompTools category={"additional-resources"} />} />
                <Route path="/adult" element={<Navigate to="/all-tools" state={{tab:"Adult"}} />} />
                <Route path="/adult/*" element={<AppLoad />} />
                <Route path="/pediatric/*" element={<Navigate to="/all-tools" state={{tab:"Pediatric"}} />} />
                <Route path="/women/*" element={<Navigate to="/all-tools" state={{tab:"Pregnancy"}} />} />
                <Route path="/substance-use-disorders/*" element={<Navigate to="/all-tools" state={{tab:"Substance"}} />} />
                <Route path="/conference" element={<Conference />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/talks" element={<Talks />} />
                <Route path="about-guide" element={<AboutGuide />} />
                <Route path="methodology" element={<Methodology />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="theteam" element={<Team />} />
                <Route path="team/*" element={<Team />} />
                <Route path="about-residency" element={<Residency />} />
                <Route path="news" element={<News />} />
                <Route path="usage" element={<Navigate to="about-guide" />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="mobile-app" element={<Navigate to="/" />} />
                <Route path="contact-us" element={<Contact />} />
                <Route path="/ryan" element={<Navigate to="/team/ryan" />} />
                <Route path="/lance" element={<Navigate to="/team/lance" />} />
                <Route path="/susan" element={<Navigate to="/team/susan" />} />
                <Route path="/jackson" element={<Navigate to="/team/jackson" />} />
                <Route path="/zach" element={<Navigate to="/team/zach" />} />
                <Route path="/" element={<Home />} >
                    <Route path="" element={<Welcome />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
);

function AppLoad() {

    let page = useParams()["*"];
    if (page != "") {
        let algorithm = algorithms.find(a => link_safe(a.title) === page);

        return (
            <div className="container">
                <App algo={algorithm} />
            </div>
        );
    } else {
        return (
            <div className="container">
                <App />
            </div>
        );
    }
}

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
