import React, { useRef, useState, useEffect } from 'react'
import Tool from './Tool';
import List from './List';
import Footer from '../../components/footer';
import SegmentedControl from "../components/SegmentedControl";
import Management from '../management';
import FLoatingPDF from '../components/floatingPDF';
import calcImg from '../../assets/images/calculator.png';
import switchImg from '../../assets/images/medSwitchImage.png';
import manageImg from '../../assets/images/planning.png';
import medications from '../../data/medications.json';

import {
    BrowserRouter, Route, Navigate, Routes, Outlet, Link,
    useParams
} from "react-router-dom";
import colokey from '../../assets/images/color-key.png'
import resourceImg from '../../assets/images/book.png'
import Nav from '../../nav';
import SlidingPdf from "../components/slidingPDF";
import { useLocation } from "react-router-dom";
import MedicationIos from '../../components/medicationIos';

let toc = require('../../data/toc.json');
let algorithms = require('../../data/algorithms.json');
let diagnoses = require('../../data/diagnoses.json');
let calculators = ["STOP BANG", "Child-Pugh", "CKD-Epi", "BMI (Adult)", "COWS"];
let medSwitching = ["Antidepressant Switching", "Mood Stabilizer Switching"];
let antipsychManage = ["Metabolic and Medical Disorders", "Movement Related Disorders"];
var medicationsAr = [];
var medClassAr = [];
var medNameAr = [];
var medDisAr = [];
var medComAr = [];
var medSearchAr = [];
var searchText = "";
var selectedClass = "";
var selectedMed = "";
var selectedDis = "";
var selectedCom = "";
var selectedBranch = "";
var finalMed;
var curCalResult = [];
var pdfName = "";

const MedState = {
    medClass:"class",
    medName:"name",
    medDis:"diagnosis",
    medCom:"comorbidity",
    medFinal:"final"
}

var curMedState = MedState.medClass;

const CompTools = ({ page, category }) => {
    const location = useLocation();
    let selectedState = (category=="all-tools") ? "All" : "Calculators";

    useEffect(() => {
        curCalResult = [];
        if(selectedState == "All") {
            window.scrollTo(0,0);
        }
        if(location.state != null) {
            setSelectedValue1(location.state.tab)
        }
    });

    const [pdfVisible, setShow] = useState(false)
    const [algo, setName] = useState("")
    const [selectedValue1, setSelectedValue1] = useState(selectedState);
    const [selectedIndex, setSelectedIndex] = (location.state == null) ? useState(0) : (location.state.tab == "Substance") ? useState(9) : useState(3);
    const [selectedLabel, setSelectedLabel] = (category=="all-tools") ? useState("All Tools") : useState("Calculators");
    const [detailLabel, setDetailLabel] = (category=="all-tools") ? useState("") : useState("");
    const [curAr, setcurAr] = useState(calculators);
    const [selectedCalc, setselectedCalc] = useState("");
    const [calResult, setcalResult] = useState("");
    const [medVisible, setMedVisible] = useState(false);
    const [floatPdfVisible, setFloatPDF] = useState("hidden")

    var algoIndex = 0;
    let displayCalResult = (c, index) => {
        if(curCalResult.length >= (index+1)) {
            if(curCalResult[index] !== c) {
                curCalResult[index] = c;
            } else {
                return
            }
        } else {
            curCalResult.push(c);
        }
        
        var ret = [];
        curCalResult.forEach(r => {
            ret.push(<div className="card card-rec">
            <div className="card-body">
                <h5 className="card-title">{c.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted"
                    dangerouslySetInnerHTML={{ __html: r.text }} />
            </div>
        </div>);
        });
        setcalResult(ret);
    }

    let changeFilter = (val) => {

        setSelectedValue1(val);
        switch(val) {
            case "All":
                setSelectedLabel("All Tools");
                setDetailLabel("");
            break; 
            case "Adult":
                setSelectedLabel("Adult (including special populations)");
                setDetailLabel("");
            break;
            case "Pediatric":
                setSelectedLabel("Pediatric");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Defined as age &#60; 18 years</li></ul>);
            break;
            case "Pregnancy":
                setSelectedLabel("Perinatal and Women’s");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Further divided into preconception, antepartum, and postpartum</li></ul>);
            break;
            case "Geriatric":
                setSelectedLabel("Geriatric");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Defined as age ≥ 65 years</li></ul>);
            break;
            case "Cardiac":
                setSelectedLabel("Cardiac");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Defined as presence of myocardial infarction within the last 6 months, cardiomyopathy with left ventricular dysfunction, or ventricular arrhythmia</li></ul>);
            break;
            case "Hepatic":
                setSelectedLabel("Hepatic");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Defined as presence of cirrhosis or other disease that impacts liver function</li>
                <li style={{fontSize:"12pt"}}>Severity classified based on Child-Pugh score</li>
                <li style={{fontSize:"12pt"}}>Dosing recommendations based on Child-Pugh scores</li></ul>);
            break;
            case "Renal":
                setSelectedLabel("Renal");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Defined as presence of kidney disease that impacts renal function and generally estimated by a threshold of eGFR &#60; 90 mL/min/1.73m&#178;</li>
                <li style={{fontSize:"12pt"}}>eGFR calculations based on CKD-EPI estimation</li>
                <li style={{fontSize:"12pt"}}>CKD-EPI calculations without race used in this application</li></ul>);
            break; 
            case "Obesity":
                setSelectedLabel("Obesity");
                setDetailLabel(<ul><li style={{fontSize:"12pt"}}>Defined as BMI ≥ 30 kg/m&#178;</li></ul>);
            break; 
            case "Substance":
                setSelectedLabel("Substance Abuse Disorders");
                setDetailLabel("");
            break; 
            case "Calculators":
                setSelectedLabel("Calculators");
                setDetailLabel("");
                setcurAr(calculators);
            break;
            case "Switching":
                setSelectedLabel("Medication Switching");
                setDetailLabel("");
                setcurAr(medSwitching);
            break;
            case "Antipsychotic":
                setSelectedLabel("Antipsychotic Management");
                setDetailLabel("");
                setcurAr(antipsychManage);
            break;
            case "Medications":
                medications.forEach(med => {if(!medClassAr.includes(med.class)){medClassAr.push(med.class)}});
                medClassAr.sort();
                medicationsAr = medClassAr;
                curMedState = MedState.medClass;
                setMedVisible(false);
                setSelectedLabel("Medications");
                setDetailLabel("");
                setcurAr(medicationsAr);
            break;
        }

    }

    let renderCalculators = (diag, p) => {
        let key = p + diag;
        
        var algos = [];

        algoIndex +=1;
        let section = "collapseAlgo" + algoIndex;
        let hsection = "#" + section;


        if(selectedValue1 == "Antipsychotic") {
            let algorithm = algorithms.find(a => a.title.includes(diag));
            
            return (
                <li key={key}>
                    <a href="javascript:void(0)" onClick={() => {setShow(true); setName(algorithm);}} style={{width:"100%"}}>
                        <p className="toolCard">
                            {diag}
                        </p>
                    </a>
                </li>
            )
        } else if(selectedValue1 == "Medications") {
            return (
                <li key={key}>
                    <a href="javascript:void(0)" onClick={() => {setSelectedMed(diag)}} style={{width:"100%"}}>
                        <p className="toolCard">
                            {diag}
                        </p>
                    </a>
                </li>
            )
        } else {
            return (
                <li key={key}>
                    <a href="javascript:void(0)" onClick={() => {setselectedCalc(diag); setShow(true);}} style={{width:"100%"}}>
                        <p className="toolCard">
                            {diag}
                        </p>
                    </a>
                </li>
            )
        } 

    }


    let searchMedChanged = (event) => {
        searchText = event.target.value;
        medSearchAr = [];
        if(searchText.length >= 3) {
            curMedState = MedState.medName;
            let tempMeds = medications.filter(m => m.name.toLowerCase().includes(searchText.toLowerCase()));
            tempMeds.forEach(med => {if(!medSearchAr.includes(med.name)){medSearchAr.push(med.name)}});
            medicationsAr = medSearchAr;
        } else {
            curMedState = MedState.medClass;
            medicationsAr = medClassAr;
        }
        setcurAr(medicationsAr);
    }

    let prevMedList = () => {
        switch(curMedState) {
            case MedState.medClass:
                medSearchAr = [];
                break;
            case MedState.medName:
                curMedState = MedState.medClass;
                medicationsAr = medClassAr;
                break;
            case MedState.medDis:
                curMedState = MedState.medName;
                if(searchText.length >= 3) {
                    medicationsAr = medSearchAr;
                } else {
                    medicationsAr = medNameAr;
                }
                break;
            case MedState.medCom:
                curMedState = MedState.medDis;
                medicationsAr = medDisAr;
                break;
            case MedState.medFinal:
                curMedState = MedState.medCom;
                medicationsAr = medComAr;
                setMedVisible(false);
                break;
        }
        setcurAr(medicationsAr);
        //Clear search field when going all the way back to the beginning 
        if(curMedState == MedState.medClass && searchText != "") {
            searchText = "";
            document.getElementById("medSearchField").value = "";
        }
    }

    let setSelectedMed = (diag) => {
        switch (curMedState) {
            case MedState.medClass:
                medNameAr = [];
                curMedState = MedState.medName;
                selectedClass = diag;
                let tempMeds = medications.filter(m => m.class == selectedClass);
                tempMeds.forEach(med => {if(!medNameAr.includes(med.name)){medNameAr.push(med.name)}});
                medNameAr.sort();
                medicationsAr = medNameAr;
                break;
            case MedState.medName:
                medDisAr = [];
                curMedState = MedState.medDis;
                selectedMed = diag;
                let tempMedsDis = medications.filter(m => m.name == selectedMed);
                tempMedsDis.forEach(med => {if(!medDisAr.includes(med.diagnosis)){medDisAr.push(med.diagnosis)}});
                medDisAr.sort();
                medicationsAr = medDisAr;
                break;
            case MedState.medDis:
                medComAr = [];
                curMedState = MedState.medCom;
                selectedDis = diag;
                let tempMedsCom = medications.filter(m => m.name == selectedMed && m.diagnosis == selectedDis);
                tempMedsCom.forEach(med => {if(med.population != null){
                                                med.population.forEach(com => {
                                                                                if(med.branch.length > 0) {
                                                                                    if(!medComAr.includes(capitalize(com) + " (" + med.branch + ")")){
                                                                                        medComAr.push(capitalize(com) + " (" + med.branch + ")");
                                                                                    }
                                                                                } else {
                                                                                    if(!medComAr.includes(capitalize(com))){
                                                                                        medComAr.push(capitalize(com));
                                                                                    }
                                                                                }
                                                                                })
                                            }});
                medComAr.sort();
                medicationsAr = medComAr;
                break;
            case MedState.medCom:
                curMedState = MedState.medFinal;
                if(diag.includes("(")) {
                    selectedCom = diag.split(" (")[0];
                    selectedBranch = diag.split(" ")[1].replace('(', '').replace(')', '');
                } else {
                    selectedCom = diag;
                    selectedBranch = "";
                }
                if(selectedBranch == "") {
                    finalMed = medications.filter(m => m.name == selectedMed && m.diagnosis == selectedDis && m.population.includes(selectedCom.toLowerCase()))[0];
                } else {
                    finalMed = medications.filter(m => m.name == selectedMed && m.diagnosis == selectedDis && m.population.includes(selectedCom.toLowerCase()) && m.branch.includes(selectedBranch))[0];
                }
                pdfName = algorithms.find(diag => diag.diagnosis == selectedDis && diag.population == selectedCom).pdf
                setMedVisible(true);
                break;
        }
        setcurAr(medicationsAr);
    }

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    let renderAlgoritms = (diag, p) => {
        let key = p + diag.title;
        var diagnosis = diag;
        var algorithm = null;
        
        
        var algos = [];
        
        algoIndex +=1;
        let section = "collapseAlgo" + algoIndex;
        let hsection = "#" + section;
        
        if(p == "All") {
            algos = algos.concat(diagnosis.category);
            algos = algos.concat(diagnosis.comorbidities);
            algos = algos.concat(diagnosis.pregnancy);
            
            if(algos.includes("Women")) {
                algos.splice(algos.indexOf("Women"), 1);
            }
        } else if(p == "Adult") {
           if(!diag.category.includes("Adult")) {
                return;
           }
            algos.push("Adult");
            algos = algos.concat(diagnosis.comorbidities);
            if(algos.includes("Geriatric")) {
                algos.splice(algos.indexOf("Geriatric"), 1);
            }
        } else if(p == "Pregnancy") {
            if(!diag.category.includes("Women")) {
                return;
            }
            algos = algos.concat(diagnosis.pregnancy);
        } else if(p == "Substance") {
            let al = algorithms.find(a => a.diagnosis == diagnosis.title);
            if(al.algorithm != "aud" && al.algorithm != "benzo" && al.algorithm != "oud" && al.algorithm != "stimulant" && al.algorithm != "tud") {
                return;
            }
            algos = algos.concat(diagnosis.category);
            algos = algos.concat(diagnosis.comorbidities);
            algos = algos.concat(diagnosis.pregnancy);
            if(algos.includes("Women")) {
                algos.splice(algos.indexOf("Women"), 1);
            }
        } else if(p == "Pediatric") {
            if(diag.category.includes("Adolescent") && diag.category.includes("Pediatric")) {
                var ado_algorithm = algorithms.find(a => a.population == "Adolescent" && a.diagnosis == diagnosis.title);
                var ped_algorithm = algorithms.find(a => a.population == "Pediatric" && a.diagnosis == diagnosis.title);
                return (
                    <li key={key}>
                        <p className="toolCard">
                            <span data-toggle="collapse" data-bs-toggle="collapse" href={hsection} role="button" aria-expanded="false" aria-controls={section} style={{width:"100%"}}>
                            <a className='compLink' href="javascript:void(0)">{diagnosis.title}</a>
                            <span style={{float:"right"}}>˅</span>
                            </span>
                            <div className="collapse" id={section} style={{marginTop:"-20px"}}>
                            <ul style={{display:"table", paddingTop:"10px"}}>
                            <li key={key} style={{listStyleType:"circle"}}>
                                <a href="javascript:void(0)" onClick={() => {setShow(true); setName(ado_algorithm);}} style={{width:"100%"}}>
                                    <p>
                                        {ado_algorithm.title}
                                        {ado_algorithm.algorithm !== undefined && (!diagnosis || !ado_algorithm || !ado_algorithm.pdf || ado_algorithm.pdf.length == 0) ? (
                                            <span>- comming soon</span>
                                        ) : null}
                                    </p>
                                </a>
                            </li>
                            <li key={key} style={{listStyleType:"circle"}}>
                                <a href="javascript:void(0)" onClick={() => {setShow(true); setName(ped_algorithm);}} style={{width:"100%"}}>
                                    <p>
                                        {ped_algorithm.title}
                                        {ped_algorithm.algorithm !== undefined && (!diagnosis || !ped_algorithm || !ped_algorithm.pdf || ped_algorithm.pdf.length == 0) ? (
                                            <span>- comming soon</span>
                                        ) : null}
                                    </p>
                                </a>
                            </li>
                            </ul>
                            </div>
                        </p>
                    </li>
                );
            } else if(diag.category.includes("Adolescent") && !diag.category.includes("Pediatric")){
               algorithm = algorithms.find(a => a.population == "Adolescent" && a.diagnosis == diagnosis.title);
            } else if(!diag.category.includes(p) && !diag.comorbidities.includes(p) ) {
                let al = algorithms.find(a => a.diagnosis == diagnosis.title);
                if(al.algorithm != "binge" && al.algorithm != "bulimia" && al.algorithm != "anorexia") {
                    return;
                }
                algorithm = algorithms.find(a => a.diagnosis == diagnosis.title);
            } else {
                algorithm = algorithms.find(a => a.population == p && a.diagnosis == diagnosis.title);
            }
            
            return (
                <li key={key}>
                    <a className='compLink' href="javascript:void(0)" onClick={() => {setShow(true); setName(algorithm);}} style={{width:"100%"}}>
                        <p className="toolCard">
                            {algorithm.title}
                            {algorithm.algorithm !== undefined && (!diagnosis || !algorithm || !algorithm.pdf || algorithm.pdf.length == 0) ? (
                                <span>- comming soon</span>
                            ) : null}
                        </p>
                    </a>
                </li>
            )
        } else {
            if(!diag.category.includes(p) && !diag.comorbidities.includes(p)) {
                return;
            }
            algorithm = algorithms.find(a => a.population == p && a.diagnosis == diagnosis.title);
            return (
                <li key={key}>
                    <a className='compLink' href="javascript:void(0)" onClick={() => {setShow(true); setName(algorithm);}} style={{width:"100%"}}>
                        <p className="toolCard">
                            {algorithm.title}
                            {algorithm.algorithm !== undefined && (!diagnosis || !algorithm || !algorithm.pdf || algorithm.pdf.length == 0) ? (
                                <span>- comming soon</span>
                            ) : null}
                        </p>
                    </a>
                </li>
            )
            
        }

    
        if(diag.title == "Antipsychotic Management") {
            var antipsych = algorithms.filter(a => a.diagnosis == "Antipsychotic Management" && a.title != "Antipsychotic Management")
            antipsych.forEach(al => {
                al.title =  al.title.split(": ").pop();
            });
            return (
                <li key={key}>
                    <p className="toolCard">
                        <span data-toggle="collapse" data-bs-toggle="collapse" href={hsection} role="button" aria-expanded="false" aria-controls={section} style={{width:"100%"}}>
                        <a className='compLink' href="javascript:void(0)">{diagnosis.title}</a>
                        <span style={{float:"right"}}>˅</span>
                        </span>
                        <div className="collapse" id={section} style={{marginTop:"-20px"}}>
                        <ul style={{display:"table", paddingTop:"10px"}}>
                            {antipsych.map(al => renderSubLink(al, p))}
                        </ul>
                        </div>
                    </p>
                </li>
            );
        } else if(diag.title == "Opioid Use Disorder" && p != "Pregnancy") {
            var antipsych = algorithms.filter(a => a.diagnosis == "Opioid Use Disorder" && a.title != "Opioid Use Disorder")
            antipsych.forEach(al => {
                al.title =  al.title.split(": ").pop();
            });
            return (
                <li key={key}>
                    <p className="toolCard">
                        <span data-toggle="collapse" data-bs-toggle="collapse"  href={hsection} role="button" aria-expanded="false" aria-controls={section} style={{width:"100%"}}>
                        <a className='compLink' href="javascript:void(0)">{diagnosis.title}</a>
                        <span style={{float:"right"}}>˅</span>
                        </span>
                        <div className="collapse" id={section} style={{marginTop:"-20px"}}>
                        <ul style={{display:"table", paddingTop:"10px"}}>
                            {antipsych.map(al => renderSubLink(al, p))}
                        </ul>
                        </div>
                    </p>
                </li>
            );
        }

        if(diagnosis.category.length > 1 || diagnosis.comorbidities.length > 1) {
            
            return (
                <li key={key}>
                    <p className="toolCard">
                        <span data-toggle="collapse" data-bs-toggle="collapse" href={hsection} role="button" aria-expanded="false" aria-controls={section} style={{width:"100%"}}>
                        <a className='compLink' href="javascript:void(0)">{diagnosis.title}</a>
                        <span style={{float:"right"}}>˅</span>
                        </span>
                        <div className="collapse" id={section} style={{marginTop:"-20px"}}>
                        <ul style={{display:"table", paddingTop:"10px"}}>
                            {algos.map(a => renderSubLink(algorithms.find(al => {
                                                                            if(al.population == a && al.diagnosis == diagnosis.name)
                                                                            {
                                                                                return true;
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        }), p))}
                        </ul>
                        </div>
                    </p>
                </li>
            );
        } else {
            
            algorithm = algorithms.find(a => a.diagnosis == diagnosis.title);
            return (
                <li key={key}>
                    <a className='compLink' href="javascript:void(0)" onClick={() => {setShow(true); setName(algorithm);}} style={{width:"100%"}}>
                        <p className="toolCard">
                                {algorithm.title}
                                {algorithm.algorithm !== undefined && (!diagnosis || !algorithm || !algorithm.pdf || algorithm.pdf.length == 0) ? (
                                    <span>- comming soon</span>
                                ) : null}
                        </p>
                    </a>
                </li>
            )
        }
    }

    let renderSubLink = (algo, p) => {
        let key = p + algo.title;
        var diagnosis = diagnoses.find(d => d.name = algo.diagnosis);
        var algorithm = algo;
        var subText = "";
        if(algo.population.includes("Adult")) {
            subText = ", Adult";
        }
        return (
            <li key={key} style={{listStyleType:"circle"}}>
                <p>
                    {diagnosis && algorithm && algorithm.pdf && algorithm.pdf.length > 0 ? (
                        <a href="javascript:void(0)" onClick={() => {setShow(true); setName(algorithm);}}>{algorithm.title + subText}</a>
                    ) : algorithm.title}
                    {algorithm.algorithm !== undefined && (!diagnosis || !algorithm || !algorithm.pdf || algorithm.pdf.length == 0) ? (
                        <span>- comming soon</span>
                    ) : null}
                </p>
            </li>
        );
    }

    if(category == "all-tools") {
        return (
            <div>
                <div className="desktop">
                    <div id="collapseDiv" className={"container " + (pdfVisible ? "collapseDivClose" : "collapseDivOpen")}>
                        <SegmentedControl
                        name="group-1"
                        callback={(val) => changeFilter(val)}
                        controlRef={useRef()}
                        className="controls-container"
                        subClassName={"controls"}
                        defaultIndex={selectedIndex}
                        segments={[
                            {
                            label: "All Tools",
                            value: "All",
                            ref: useRef()
                            },
                            {
                            label: "Adult",
                            value: "Adult",
                            ref: useRef()
                            },
                            {
                            label: "Pediatric",
                            value: "Pediatric",
                            ref: useRef()
                            },
                            {
                            label: "Perinatal",
                            value: "Pregnancy",
                            ref: useRef()
                            },
                            {
                            label: "Geriatric",
                            value: "Geriatric",
                            ref: useRef()
                            },
                            {
                            label: "Cardiac",
                            value: "Cardiac",
                            ref: useRef()
                            },
                            {
                            label: "Hepatic",
                            value: "Hepatic",
                            ref: useRef()
                            },
                            {
                            label: "Renal",
                            value: "Renal",
                            ref: useRef()
                            },
                            {
                            label: "Obesity",
                            value: "Obesity",
                            ref: useRef()
                            },
                            {
                            label: "Substance Use Disorders",
                            value: "Substance",
                            ref: useRef()
                            }
                        ]}
                        />
                    </div>
                </div>
                <div className='mobile'>
                    <div id="collapseDiv" className={"" + (pdfVisible ? "collapseDivClose" : "collapseDivOpen")}>
                        <select className="form-control" id="test" onChange={(e) => changeFilter(e.target.value)} style={{marginBottom:"25px", marginLeft:"4px"}}> 
                            <option selected key="question" value="All">All Tools</option>
                            <option key="event" value="Adult">Adult</option>
                            <option key="suggestion" value="Pediatric">Pediatric</option>
                            <option key="other" value="Geriatric">Geriatric</option>
                            <option key="other" value="Pregnancy">Perinatal</option>
                            <option key="other" value="Cardiac">Cardiac</option>
                            <option key="other" value="Hepatic">Hepatic</option>
                            <option key="other" value="Renal">Renal</option>
                            <option key="other" value="Obesity">Obesity</option>
                            <option key="other" value="Substance">Substance Use Disorders</option>
                        </select>
                    </div>
                </div>
                <section>
                    <div>
                        <div className="row">
                            <div className="col-md-3 col-sm-6 desktop">
                                <div className="sticky colorKey">
                                    <img style={{width:"100%", borderRadius:"18px", boxShadow:"0px 0px 4px #ccc"}} src={colokey} />
                                    <p><strong>If you're new to The Waco Guide, please be sure to review the </strong><a href="/methodology"><strong>intended use</strong></a><strong> of this decision-support tool, and make sure you’re looking at the proper age range and comorbidity (if applicable) for your patient.</strong></p>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-6 desktop">
                                {pdfVisible == false ?
                                    <div className='compToolArea'>
                                        <h3>{selectedLabel}</h3>
                                        <div style={{display:"block"}}>
                                            {detailLabel}
                                            <hr/>
                                        </div>
                                        <ul style={{paddingLeft:"0px"}}>
                                            <div className='grid-container comp-grid'>
                                                {diagnoses.map(diag => renderAlgoritms(diag, selectedValue1))}
                                            </div>
                                        </ul>
                                    </div> : 
                                    <div>
                                        <SlidingPdf algo={algo} isHidden={pdfVisible} setShowPDF={setShow}/>
                                    </div>
                                }
                            </div>
                            <div className="mobile">
                                {pdfVisible == false ?
                                    <div className='compToolArea'>
                                        <h3>{selectedLabel}</h3>
                                        <div style={{display:"block"}}>
                                            {detailLabel}
                                            <hr/>
                                        </div>
                                        <ul style={{paddingLeft:"0px"}}>
                                            <div className='grid-container comp-grid'>
                                                {diagnoses.map(diag => renderAlgoritms(diag, selectedValue1))}
                                            </div>
                                        </ul>
                                    </div> : 
                                    <div>
                                        <SlidingPdf algo={algo} isHidden={pdfVisible} setShowPDF={setShow}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div >
                </section>
                <Footer />
            </div>
        );
    } else {
        return (
            <div>
                <div className='desktop'>
                    <div id="collapseDiv" className={"container " + (pdfVisible ? "collapseDivClose" : "collapseDivOpen")}>
                        <SegmentedControl
                        name="group-1"
                        callback={(val) => changeFilter(val)}
                        controlRef={useRef()}
                        className="controls-container-lg"
                        subClassName={"controls-lg"}
                        segmentClassName={"segment-lg"}
                        defaultIndex={0}
                        segments={[
                            {
                            label: "Calculators",
                            value: "Calculators",
                            ref: useRef(),
                            image:calcImg
                            },
                            {
                            label: "Medication Switching",
                            value: "Switching",
                            ref: useRef(),
                            image:switchImg
                            },
                            {
                            label: "Antipsychotic Management",
                            value: "Antipsychotic",
                            ref: useRef(),
                            image:manageImg
                            },
                            {
                            label: "Medications",
                            value: "Medications",
                            ref: useRef(),
                            image:manageImg
                            }
                        ]}
                        />
                    </div>
                </div>
                <div className='mobile'>
                    <div id="collapseDiv" className={"container " + (pdfVisible ? "collapseDivClose" : "collapseDivOpen")}>
                        <select className="form-control" id="test" onChange={(e) => changeFilter(e.target.value)} style={{marginBottom:"25px", marginLeft:"4px"}}> 
                            <option selected key="question" value="Calculators">Calculators</option>
                            <option key="event" value="Switching">Medication Switching</option>
                            <option key="suggestion" value="Antipsychotic">Antipsychotic Management</option>
                            <option key="suggestion" value="Medications">Medications</option>
                        </select>
                    </div>
                </div>
                <section>
                    <div style={{position:"relative", float:"center"}}>
                        <FLoatingPDF pdfName={pdfName} isHidden={floatPdfVisible} togglePDF={() => setFloatPDF("hidden")}/>
                    </div>
                    {selectedValue1 != "Antipsychotic" ? 
                        selectedValue1 != "Switching" ?
                            <div className="container" style={{maxWidth:"100%"}}>
                                <div className="row">
                                    {pdfVisible == false ?
                                        <div className="col-md-9 col-sm-6"  style={{marginLeft:"50%", transform:"translateX(-50%)"}}>
                                            <div style={{alignContent:"center"}}>
                                                <h3>{selectedLabel}</h3>
                                                <div style={{display:"block"}}>
                                                    {detailLabel}
                                                    <hr/>
                                                </div>
                                                {selectedValue1 == "Medications" && curMedState != MedState.medClass ? <input style={{paddingTop:"0px", paddingBottom:"0px", minHeight:"45px", marginRight:"0px", display:"inline-block", marginBottom:"20px", minWidth:"125px"}} className={"btn btn-primary"} type="button" onClick={() => {prevMedList()}} value="< Back" />
                                                :
                                                ""}
                                                {selectedValue1 == "Medications" && curMedState == MedState.medFinal ? <input style={{paddingTop:"0px", paddingBottom:"0px", minHeight:"45px", marginRight:"0px", display:"inline-block", marginBottom:"20px", marginLeft:"10px"}} className={"btn btn-primary"} type="button" onClick={() => {setFloatPDF("visible")}} value="View Tool" />
                                                :
                                                ""}
                                                {selectedValue1 == "Medications" && curMedState == MedState.medClass || selectedValue1 == "Medications" && curMedState == MedState.medName && searchText.length >= 3 ? 
                                                    <input className='form-control contact contact-input' id="medSearchField" type="text" placeholder='Search Medications' name="medication" onChange={searchMedChanged} defaultValue={searchText}/>
                                                    :
                                                    ""}
                                                {medVisible ? 
                                                        <div >
                                                            <MedicationIos medication={finalMed} showClass={false} showBranch={true} forceExpand={true} index={0} />
                                                        </div>
                                                    :
                                                    <ul style={{paddingLeft:"0px"}}>
                                                        <div style={{gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 48%))"}} className='grid-container'>
                                                            {curAr.map(cal => renderCalculators(cal, ""))}
                                                        </div>
                                                    </ul>
                                                }
                                            </div></div> : 
                                        <div className="col-md-6 col-sm-6">
                                            <div className="row justify-content-center d-block">
                                                <input style={{width:"15%", marginRight:"0px", display:"inline-block", marginBottom:"20px", minWidth:"125px"}} className={"btn btn-primary "} type="button" onClick={() => {setShow(false);  curCalResult=[]; setcalResult()}} value="< Back" />
                                                <h1 className='titleFont' style={{width:"80%", textAlign:"center", display:"inline-block", height:"65px", marginBottom:"20px"}}><strong>{selectedCalc}</strong></h1>
                                                <Management algo={selectedCalc} setcalResult={displayCalResult}/>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-5 col-sm-6">
                                        <div className="sticky colorKey" style={{paddingTop:"120px"}}>
                                            {calResult}
                                        </div>
                                    </div>
                                </div>
                            </div >
                            :
                            <div className="container" style={{maxWidth:"100%"}}>
                            <div className="row">
                                {pdfVisible == false ?
                                    <div className="col-md-9 col-sm-6"  style={{marginLeft:"50%", transform:"translateX(-50%)"}}>
                                        <div style={{alignContent:"center"}}>
                                            <h3>{selectedLabel}</h3>
                                            <div style={{display:"block"}}>
                                                {detailLabel}
                                                <hr/>
                                            </div>
                                            <ul style={{paddingLeft:"0px"}}>
                                                <div style={{gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 48%))"}} className='grid-container'>
                                                    {curAr.map(cal => renderCalculators(cal, ""))}
                                                </div>
                                            </ul>
                                        </div></div> : 
                                    <div className="row">
                                        <div className="row justify-content-center">
                                            <input style={{width:"15%", marginRight:"0px", display:"inline-block", marginBottom:"20px", minWidth:"125px"}} className={"btn btn-primary "} type="button" onClick={() => {setShow(false);  curCalResult=[]; setcalResult()}} value="< Back" />
                                            <h1 className='titleFont' style={{width:"80%", textAlign:"center", display:"inline-block", height:"65px", marginBottom:"20px"}}><strong>{selectedCalc}</strong></h1>
                                            <div className="col-md-6 col-sm-6">
                                                <Management algo={selectedCalc} setcalResult={displayCalResult}/>
                                            </div>
                                            <div className="col-md-5 col-sm-6" style={{paddingTop:"20px"}}>
                                                {calResult}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div >
                        : 
                        <div className="container" style={{maxWidth:"100%"}}>
                            <div className="row">
                                <div className="col-md-3 col-sm-6 desktop">
                                    <div className="sticky colorKey">
                                        <img style={{width:"100%", borderRadius:"18px", boxShadow:"0px 0px 4px #ccc"}} src={colokey} />
                                        <p><strong>If you're new to The Waco Guide, please be sure to review the </strong><a href="/about-guide"><strong>intended use</strong></a><strong> of this decision-support tool, and make sure you’re looking at the proper age range and comorbidity (if applicable) for your patient.</strong></p>
                                    </div>
                                </div>
                                <div className="col-md-9 col-sm-6">
                                    {pdfVisible == false ?
                                        <div className='compToolArea'>
                                            <h3>{selectedLabel}</h3>
                                            <div style={{display:"block"}}>
                                                {detailLabel}
                                                <hr/>
                                            </div>
                                            <ul style={{paddingLeft:"0px"}}>
                                                <div style={{gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 48%))"}} className='grid-container'>
                                                 {curAr.map(cal => renderCalculators(cal, ""))}
                                                </div>
                                            </ul>
                                        </div> : 
                                        <div>
                                            <SlidingPdf algo={algo} isHidden={pdfVisible} setShowPDF={setShow}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div >}
                </section>
                
                <Footer />
            </div>
        );
    }
}


export default CompTools;