import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import meeting3 from '../../assets/images/topw.jpg';
import aerialTeamImg from '../../assets/images/Team_Photos_appTeam_Photos_app_aerial view.jpg';
import googleStore from '../../assets/images/google.png';
import appleStore from '../../assets/images/appleStore.png';
import amazonStore from '../../assets/images/amazonStore.png';
import moreFAQ from '../../assets/images/moreFAQ.png';

const About = ({ }) => {

    return (
        <div className="container">
            <div className="grid-2" style={{paddingBottom:"0px"}}>
                <div style={{paddingRight:"20px", paddingBottom:"0px"}}>
                    <h2 style={{marginTop:"0px", textalign:"left", whitespace:"pre-wrap"}}>
                        <span style={{paddingBottom:"25px"}}><strong>WHY WE NEED THE WACO GUIDE</strong></span><br/>
                    </h2>
                    <div class="video-container" style={{marginTop:"40px"}}>
                        <iframe style={{borderRadius:"8px"}} width="90%" src="https://www.youtube.com/embed/sljaMpW21q4" title="The Waco Guide - Why" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div>
                    <p>
                    The Waco Guide to Psychopharmacology in Primary Care® was created to support primary care clinicians managing mental and substance use disorders, especially in rural and underserved communities. Primary care is the de facto mental health system in the United States, providing services for the majority of patients who enter treatment for behavioral health disorders. However, two-thirds of primary care clinicians lack access to specialty services and many report discomfort with prescribing psychotropic medications.
                    </p>
                    <p>
                    The Waco Guide’s library of clinical decision support tools facilitates psychopharmacological treatment of routine and complex mental and behavioral health disorders, including substance use disorders, by providing top-level evidence and expert opinion, tailored to primary care®. The Waco Guide was developed free of industry funding by faculty of the Waco Family Medicine Residency, one of the nation's top family medicine residency programs, in consultation with faculty of the <a href="https://mghcme.org/mgh-visiting/">MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry</a>, affiliated with Harvard Medical School. The tools’ recommended interventions include first, second, and third line pharmacotherapy; augmentation options; titration schedules; and potential side effects. Additionally, tools provide focused recommendations based on patient age, special populations, and comorbidities. To learn more about the process that informed tool development, check out the <Link to="/methodology">Methodology</Link> behind The Guide.
                    </p>
                </div>
            </div>
            <div className="grid-2" style={{paddingBottom:"0px"}}>
                <div className='gridItem'>
                    <h2 style={{marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%"}}>
                        <strong>GUIDE ON THE GO</strong>
                    </h2>
                    <div className="store-link" style={{width:"60%", padding:"10px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                        <a href="https://apps.apple.com/us/app/waco-guide-psychopharmacology/id1503707099"><img src={appleStore} /></a>
                    </div>
                    <div className="store-link" style={{width:"60%", padding:"10px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                        <a href="https://play.google.com/store/apps/details?id=org.wacoguide.app"><img src={googleStore} /></a>
                    </div>
                    <div className="store-link" style={{width:"60%", padding:"0px 10px 10px 10px", marginLeft:"50%", transform:"translateX(-50%)"}}>
                        <a><img src={amazonStore} /></a>
                        <h6 class="center">(coming soon)</h6>
                    </div>
                </div>
                <div className="text-center gridItem">
                    <img src={aerialTeamImg} />
                </div>
            </div>
           <div style={{paddingBottom:"50px"}}>
                <h2 style={{marginTop:"40px", marginBottom:"25px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%", textAlign:"center"}}>
                    <strong>FREQUENTLY ASKED QUESTIONS</strong>
                </h2>
                <div>
                    <div>
                        <h2 className='subtitle'>
                            <strong>How often are tools reviewed and updated?</strong>
                        </h2>
                    </div>
                    <div>
                        <div>
                        <p className='answers' style={{whitespace:"pre-wrap"}}>
                        The editorial team is committed to continuous review to ensure fidelity to relevant updates in the psychiatric literature. We evaluate all CDSTs every two years alongside consultation with faculty of the MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry and modify the tools as needed using our consensus-building method. Additionally, we encourage users to provide feedback on the CDSTs in a real-time peer-review process available through the website and mobile app. 
                        </p>
                        </div>
                    </div>
                </div>
                <br/>
                <div>
                    <div>
                        <h2 className='subtitle'>
                            <strong>Do you offer presentations or trainings to medical groups and organizations on using The Waco Guide in clinical practice?</strong>
                        </h2>
                    </div>
                    <div>
                        <div>
                        <p className='answers' style={{whitespace:"pre-wrap"}}>
                            Yes! Please submit a request via our <Link to="/contact-us">Contact Us</Link> page.
                        </p>
                        </div>
                    </div>
                </div>
                <br/>
                <div>
                    <div>
                        <h2 className='subtitle'>
                            <strong>I can’t find the References for some tools – am I missing something?</strong>
                        </h2>
                    </div>
                    <div>
                        <div>
                            <p className='answers' style={{whitespace:"pre-wrap"}}>
                            While many of our tools include strength of recommendation ratings and accompanying key references, we are still in the process of developing these for some tools (e.g., tools tailored to special populations). 
                            </p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div>
                    <div>
                        <Link to='/faq'><img style={{width:"200px"}} src={moreFAQ}/></Link>
                    </div>
                </div>
            </div>
            <div className="grid-2">
                <div>
                    <img src={meeting3} style={{width:"100%"}}/>
                </div>
                <div>
                    <h2 style={{marginTop:"0px", textalign:"left", whitespace:"pre-wrap", maxWidth:"90%"}}>
                        <strong>COPYRIGHT AND PRIVACY</strong>
                    </h2>
                    <p>
                    Content published in The Waco Guide, including but not limited to decision support tools, color schema, mapping, and flow charts, is owned and copyrighted by Heart of Texas Community Health Center and may not be copied, reproduced, distributed, or transmitted in any form without prior written permission of the Heart of Texas Community Health Center and without express attribution to the Heart of Texas Community Health Center.
                    </p>
                    <p>
                    Notwithstanding the above, the Heart of Texas Community Health Center provides a limited license to you to copy and/or distribute content published in The Waco Guide to others for scholarly or academic teaching purposes. In such event, you must expressly attribute the Heart of Texas Community Health Center as the copyright owner, The Waco Guide to Psychopharmacology in Primary Care® as the source, and the page number(s) of the copied and distributed content.
                    </p>
                    <h6>Suggested Citation</h6>
                    <p>Laschober RD, Kelley LP, Sartor ZR, Johnson S, Griggs JO. Waco Guide to Psychopharmacology in Primary Care. https://wacoguide.org/ </p>

                </div>
            </div>
            
        </div>
    )
};

export default About