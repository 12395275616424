import React from 'react'
import './methodology.css';

const Privacy = ({ }) => {
    return (
        <div className="container">
            <div className="row">
                <div style={{width:"30%"}}>
                    <h1>
                        <strong>PRIVACY</strong>
                    </h1>
                </div>
                <div style={{width:"70%"}}>
                <p><strong>Privacy Policy</strong></p>
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <p>&nbsp;</p>
                <p><strong>Interpretation and Definitions</strong></p>
                <p><strong>Interpretation</strong></p>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <p>&nbsp;</p>
                <p><strong>Definitions</strong></p>
                <p>For the purposes of this Privacy Policy:</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Account</strong>&nbsp;means a unique account created for You to access our Service or parts of our Service.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Affiliate</strong>&nbsp;means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Application</strong>&nbsp;means the software program provided by the Company downloaded by You on any electronic device, named Waco Guide to Psychopharmacology in Primary Care</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Business</strong>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California. In reference to Waco Guide to Psychopharmacology in Primary Care App, we do not use, collect, or store any personal/usage data. </p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Company</strong>&nbsp;(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Heart of Texas Community Health Center (dba Waco Family Medicine), 1600 Providence Dr. Waco, TX 76707.</p>
                <p>For the purpose of the GDPR, the Company is the Data Controller.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Consumer</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Country</strong>&nbsp;refers to: Texas, United States</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Data Controller</strong>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Device</strong>&nbsp;means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Do Not Track</strong>&nbsp;(DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</p>
                <p><strong>Personal Data (</strong>We do not use, collect, or store any personal/usage data.)</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;is any information that relates to an identified or identifiable individual.</p>
                <p>For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</p>
                <p>For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Sale</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Service</strong>&nbsp;refers to the Application.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Service Provider</strong>&nbsp;means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Third-party Social Media Service</strong>&nbsp;refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Usage Data</strong>&nbsp;refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit). </p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>You</strong>&nbsp;means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                <p>Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.</p>
                <p><strong>&nbsp;</strong><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong><strong>&nbsp;</strong></p>
                <p><strong>Collecting and Using Your Personal Data</strong></p>
                <p><strong>Types of Data Collected</strong></p>
                <p><strong>Personal Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Usage Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Use of Your Personal Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Retention of Your Personal Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Transfer of Your Personal Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Disclosure of Your Personal Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Security of Your Personal Data</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>GDPR Privacy</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>CCPA Privacy</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Use of Personal Information for Business Purposes or Commercial Purposes</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Disclosure of Personal Information for Business Purposes or Commercial Purposes</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Sale of Personal Information</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Share of Personal Information</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Sale of Personal Information of Minors Under 16 Years of Age</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</strong></p>
                <p>Our Service does not respond to Do Not Track signals.</p>
                <p>However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</p>
                <p><strong>Children's Privacy</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Your California Privacy Rights (California's Shine the Light law)</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</strong></p>
                <p>We do not use, collect, or store any personal/usage data. </p>
                <p><strong>Links to Other Websites</strong></p>
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <p><strong>Changes to this Privacy Policy</strong></p>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <p><strong>Contact Us</strong></p>
                <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                <p>·&nbsp;&nbsp;&nbsp;&nbsp; By email:&nbsp;wacoguide@wacofhc.org</p>
                <p>&nbsp;</p>
                </div>
            </div>
        </div>
    )
};

export default Privacy