import React from 'react'
import './methodology.css';
import AdoGad from '../../assets/images/AdoGad.mp4'
import AdultGad from '../../assets/images/adultGadHep.mp4'
import AntepartumGad from '../../assets/images/antepartGad.mp4'
import samPres from '../../assets/images/pres_woman.png'
import micPres from '../../assets/images/pres_businessman.png'
import charPres from '../../assets/images/pres_pigtails.png'
import { Link } from 'react-router-dom';

const Presenation = ({ }) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
    return (
        <div className="container" style={{paddingBottom:"35px"}}>
            <div className='welcomeDiv'>
                <div className='welcomeText column' style={{float:"right"}}><h1 className='homeTitleFont' style={{paddingTop:"100px"}}><b>PSYCHOPHARMACOLOGY<br/> IN PRIMARY CARE</b><br/></h1><h6>TOP-LEVEL EVIDENCE<br/>EXPERT OPINION<br/>TAILORED TO PRIMARY CARE®</h6></div>
            </div>
            <div>
            <iframe src="https://1drv.ms/p/c/4f34f2fd9f2f0907/UQQHCS-f_fI0IIBPnRMAAAAAAJujejzd0eF8XE4?em=2&amp;wdAr=1.7777777777777777" width="100%" height="667px" frameborder="0" sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
            </div>
            <table>
                <tr>
                    <td>
                    <div style={{display:"inline-block"}}>
                        <h4>Case – Antepartum GAD</h4>
                        <video src={AntepartumGad} width='375' height='680' controls/> 
                    </div>
                    </td>
                    <td>
                        <div style={{marginTop:"278px"}}>
                            <div className='grid-30_70 card' style={{position:"relative", padding:"25px"}}>
                                <div style={{textAlign:"center"}}>
                                    <img  style={{height:"175px"}} src={samPres}/>
                                    <ol style={{paddingLeft:"0px"}}>
                                        <li style={{fontSize:"16pt", fontWeight:"bold"}}>Samatha</li>
                                        <li style={{fontSize:"16pt", fontWeight:"bold"}}>22yo G1P0 at 28w2d</li>
                                        <li style={{fontSize:"14pt"}}>Uncomplicated pregnancy</li>
                                        <li style={{fontSize:"14pt"}}>PMH: None</li>
                                    </ol>
                                </div>
                                <div>
                                    <h4>Antepartum Generalized Anxiety Disorder</h4>
                                    <ol style={{listStyleType:"disc"}}>
                                        <li style={{fontSize:"14pt"}}>Samantha is a 22yo G1P0 at 28w2d presenting for f/u of GAD</li>
                                        <li style={{fontSize:"14pt"}}>Dx at initial OB visit at 8w1d with GAD, referred for CBT with minimal response</li>
                                        <li style={{fontSize:"14pt"}}>Subsequently started on sertraline, titrated to max dose, minimal response</li>
                                        <li style={{fontSize:"14pt"}}>Presents today at 28w2d with now severe GAD, after reconfirming diagnosis and ruling out alternate etiologies you and Samantha have a risk-benefit discussion regarding a second pharmacotherapy trial</li>
                                    </ol>
                                </div>     
                            </div> 
                        </div>
                    </td>
                </tr>
            </table>  

            <table>
                <tr>
                    <td>
                        <div style={{marginTop:"278px"}}>
                            <div className='grid-30_70 card' style={{position:"relative", padding:"25px"}}>
                                <div style={{textAlign:"center"}}>
                                    <img  style={{height:"175px"}} src={micPres}/>
                                    <ol style={{paddingLeft:"0px"}}>
                                        <li style={{fontSize:"16pt", fontWeight:"bold"}}>Michael</li>
                                        <li style={{fontSize:"16pt", fontWeight:"bold"}}>52yo</li>
                                        <li style={{fontSize:"14pt"}}>PMH: Hepatic impairment</li>
                                    </ol>
                                </div>
                                <div>
                                    <h4>Adult Hepatic Generalized Anxiety Disorder</h4>
                                    <ol style={{listStyleType:"disc"}}>
                                        <li style={{fontSize:"14pt"}}>Michael is a 52yo male with severe uncontrolled GAD</li>
                                        <li style={{fontSize:"14pt"}}>He has a PMH significant for Child Pugh B Cirrhosis</li>
                                        <li style={{fontSize:"14pt"}}>He has completed 10 weeks of  Escitalopram 10mg (max appropriate dose in Cirrhosis) and desires further pharmacotherapy, what could be considered next?</li>
                                    </ol>
                                </div>     
                            </div> 
                        </div>
                    </td>
                    <td>
                    <div style={{display:"inline-block"}}>
                        <h4>Case – Adult Hepatic GAD</h4>
                        <video src={AdultGad} width='375' height='680' controls/> 
                    </div>
                    </td>
                </tr>
            </table>  

            <table>
                <tr>
                    <td>
                    <div style={{display:"inline-block"}}>
                        <h4>Case – Adolescent GAD</h4>
                        <video src={AdoGad} width='375' height='680' controls/>
                    </div>
                    </td>
                    <td>
                        
                    <div style={{marginTop:"278px"}}>
                        <div className='grid-30_70 card' style={{position:"relative", padding:"25px"}}>
                            <div style={{textAlign:"center"}}>
                                <img src={charPres} style={{height:"175px"}}/>
                                <ol style={{paddingLeft:"0px"}}>
                                    <li style={{fontSize:"16pt", fontWeight:"bold"}}>Charlotte</li>
                                    <li style={{fontSize:"16pt", fontWeight:"bold"}}>14yo</li>
                                    <li style={{fontSize:"14pt"}}>PMH: None</li>
                                </ol>
                            </div>
                            <div>
                                <h4>Adolescent Generalized Anxiety Disorder</h4>
                                <ol style={{listStyleType:"disc"}}>
                                    <li style={{fontSize:"14pt"}}>Charlotte is a 14yo female seen for severe uncontrolled GAD</li>
                                    <li style={{fontSize:"14pt"}}>Seen for past 4mo for debilitating GAD that negatively impacts academics and friendships</li>
                                    <li style={{fontSize:"14pt"}}>Alternate etiologies of symptoms ruled out after history and physical. She denies SI/HI/AVH/Mania. FMH positive for GAD in mother.</li>
                                    <li style={{fontSize:"14pt"}}>Given symptom severity, Charlotte, her parents, and you collaboratively determine pharmacotherapy and referral for CBT is in order. Fluoxetine started and titrated to max FDA approved dose. After 8wks at max dose, she has had an inadequate response.</li>
                                </ol>
                            </div>     
                        </div> 
                    </div>
                    </td>
                </tr>
            </table>                               
        </div>
    )
};

export default Presenation