import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Select from './select';
import Segment from './segment';
import CollapseSegment from './collapseSegment';
import Radio from './radio';
import Switch from './switch';
import Checkboxes from './checkboxes';
import BMI from './bmi';
import CKD from './ckd';
import MedSwitch from './medSwitch';
import waco from '../service/waco';
import MedicationIos from './medicationIos';
import TherapyIos from './therapyIos';
import viewImg from '../assets/images/file.png';
import cautionImg from "../assets/images/caution.png"
import warningImg from "../assets/images/warning.png"

import medications from '../data/medications.json';
import therapies from '../data/therapy.json';
import tallMan from '../service/tallMan.js';

const Flow = ({ goToNextPage }) => {
    return (
        <input className="btn btn-primary" type="button" onClick={goToNextPage} value="Next >" />
    );
};

const BackFlow = ({ goToPrevPage, margin = "0px 10px 0px 0px" }) => {
    return (
        <input style={{margin:margin, minWidth:"125px"}} className="btn btn-primary" type="button" onClick={goToPrevPage} value="< Back" />
    );
};

var closeOldPopUp = null;

function setPopUp(closePopUp) {
    if(closeOldPopUp) {
        closeOldPopUp();
    }
    closeOldPopUp = closePopUp;
}

var calcIndex = 0;
var showFacts = false;

function mapContent(c, diagnoses, facts, setVariable, getVariable, state, diagnosis, sortClicked, algorithm, setcalResult) {
    
    if(facts != null) {
        if (facts[c.id]) {
            console.log(facts[c.id]);
        }
    }

    let onSelected = (id, choice) => {
        console.log(id, choice);
        setVariable(id, choice.value || choice.text);
    }

    var tempDiagnosis;
    if(diagnosis != null) {
        tempDiagnosis = diagnoses.find(d => d.title == diagnosis)
    }

    if (c.id == "diagnosis") {
        c.choices = diagnoses.map(d => { return { "text": d.title } })
        c.choices.unshift({ "text": "Select" });
    }

    if (c.type == 'select') {
        return (<Select content={c} onSelected={onSelected} defaultSelected={getVariable("diagnosis")} key={c.id} />);
    }
    if (c.type == 'segment' || c.type == 'choice') {
        return (<Segment content={c} onSelected={onSelected} key={c.type + c.id} type={c.type} />);
    }
    if(c.type == 'collapse_segment') {
        return (<CollapseSegment content={c} onSelected={onSelected} key={c.type + c.id} type={c.type} />);
    }
    if (c.type == 'radio') {
        return (<Radio content={c} diagnosis={tempDiagnosis} onSelected={onSelected} key={c.id} />);
    }
    if (c.type == 'switch') {
        return (<Switch content={c} facts={facts[c.id]} setPopUp={setPopUp} showFacts={showFacts} onSelected={onSelected} defaultSelected={getVariable("diagnosis")} key={c.id} />);
    }
    if (c.type == 'checkboxes') {
        return (<Checkboxes content={c} onSelected={onSelected} defaultSelected={getVariable("diagnosis")} key={c.id} />);
    }
    if (c.type == 'set') {
        waco.setVariable(c.id, c.choices[0].text)
        return (<div key={c.type + c.id} />);
    }
    if(c.type == 'title') {
        return (<div key={c.text[0]} > <h4>{c.text[0]}</h4></div>);
    }
    if(c.type == 'collapse') {
        var text = tallMan.replaceTallMed(c.text.toString());
        text = text.replace("<pre>", "");
        text = text.replace("</pre>", "");
        text = text.replaceAll("(SOR A)", '<span><u>(SOR A)</u></span>');
        text = text.replaceAll("(SOR B)", '<span><u>(SOR B)</u></span>');

        if(waco.getVariable("has_current") == true) {
            text = text.replaceAll("<discontinue>", "Discontinue current medication.<br><br>");
        } else {
            text = text.replaceAll("<discontinue>", "");
        }

        c.text = text.replaceAll("(SOR C)", '<span><u>(SOR C)</u></span>');
        if(state == "recommendation") {
            return (
                <div className="card card-rec">
                    <div className="card-body" onClick={sortClicked}>
                        <h6 className="card-subtitle mb-2 text-muted" style={{paddingBottom:"10px", paddingTop:"10px"}}><b>{c.title}</b></h6>
                        <h6 className="card-subtitle mb-2 text-muted"
                            dangerouslySetInnerHTML={{ __html: c.text }} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="card">
                    <div className="card-body" onClick={sortClicked}>
                        <h5 className="card-title mb-2 text-muted">{c.title}</h5>
                        <h6 className="card-subtitle mb-2 text-muted"
                            dangerouslySetInnerHTML={{ __html: c.text }} />
                    </div>
                </div>
            );
        }
    }
    if(c.type == 'bmi') {
        return(<BMI content={c} setBMI={setVariable}></BMI>)
    }
    if(c.type == 'ckd') {
        return(<CKD content={c} setCKD={setVariable}></CKD>)
    }
    if(c.type == 'med_switch') {
        return(<MedSwitch content={c} setSwitch={setVariable}></MedSwitch>)
    }

    // If we are viewing a calculator, send any c.type==overlay back up to the <CompTool> to render on the right side floating
    if(setcalResult!=null) {
        setcalResult(c, calcIndex)
        calcIndex ++;
    } else if(state == "recommendation") {

        var text = tallMan.replaceTallMed(c.text.toString());
        text = text.replace("<pre>", "");
        text = text.replace("</pre>", "");
        text = text.replaceAll("(SOR A)", '<span><u>(SOR A)</u></span>');
        text = text.replaceAll("(SOR B)", '<span><u>(SOR B)</u></span>');

        if(waco.getVariable("has_current") == true) {
            text = text.replaceAll("<discontinue>", "Discontinue current medication.<br><br>");
        } else {
            text = text.replaceAll("<discontinue>", "");
        }

        c.text = text.replaceAll("(SOR C)", '<span><u>(SOR C)</u></span>');



        if(text.includes("<caution>") || text.includes("<warning>")) {
            return (
                <div className="card card-rec">
                    <div className="card-body" onClick={sortClicked}>
                        <h5 className="card-title">{c.name}</h5>
                        <div className='grid-2' style={{gridTemplateColumns:"35px auto", padding:"0"}} >
                            {text.includes("<caution>") == true ? <img style={{width:"35px", marginTop:"-15px"}} src={cautionImg}/> : <img style={{width:"35px", marginTop:"-15px"}} src={warningImg}/>}
                            <h6 className="card-subtitle mb-2 text-muted" dangerouslySetInnerHTML={{ __html: c.text }} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="card card-rec">
                    <div className="card-body" onClick={sortClicked}>
                        <h5 className="card-title">{c.name}</h5>
                        <div>
                            <h6 className="card-subtitle mb-2 text-muted" dangerouslySetInnerHTML={{ __html: c.text }} />
                        </div>
                    </div>
                    {document.location.hostname.endsWith("wacoguide.org") == false ?
                        <h6><strong>ID {c.uid}</strong></h6> : ""}
                </div>
            );
        }
    } else {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{c.name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted"
                        dangerouslySetInnerHTML={{ __html: c.text }} />
                </div>
            </div>
        );
    }
}

function renderMedications(meds, diagnosis, branch, population) {
    var mIndex = -1;
    var i = meds[0];
    var counts = {};
    for (const m of meds) {
        counts[m] = counts[m] ? counts[m] + 1 : 1;
    }

    meds = meds.map(m => {
        mIndex++;
        var medication;
        if(m.includes("<b>") || m.includes("<i>")) {
            medication = {name:m};
            return medication;
        }
        if(counts[m] >= 2) {
            if(meds.indexOf(m) == mIndex) {
                medication = medications.find(mm => mm.id === m &&
                    mm.diagnosis === diagnosis &&
                    mm.branch.length == 0 &&
                        ((mm.population.length == 0) || population != null && mm.population.includes(population))) || {name: m};
            } else {
                medication = medications.find(mm => mm.id === m &&
                    mm.diagnosis === diagnosis &&
                    (branch != null && mm.branch.includes(branch)) &&
                        ((mm.population.length == 0) || population != null && mm.population.includes(population))) || {name: m};
            }
            if(medication.id == null) {
                return null;
            }
        } else {
            if(branch != "none") {
                medication = medications.find(mm => mm.id === m &&
                    mm.diagnosis === diagnosis &&
                    (branch != null && mm.branch.includes(branch)) &&
                    ((mm.population.length == 0) || population != null && mm.population.includes(population)));
                if(medication == null) {
                    medication = medications.find(mm => mm.id === m &&
                        mm.diagnosis === diagnosis &&
                        mm.branch.length == 0 &&
                            ((mm.population.length == 0) || population != null && mm.population.includes(population))) || {name: m};
                }
            } else {
                medication = medications.find(mm => mm.id === m &&
                    mm.diagnosis === diagnosis &&
                    ((mm.branch.length == 0) || branch != null && mm.branch.includes(branch)) &&
                        ((mm.population.length == 0) || population != null && mm.population.includes(population))) || {name: m};
            }
        }
        return medication;
    }).filter(m => m != null);

    

    var subs = meds.filter(m => m.name.includes("<b>") || m.name.includes("<i>"));

    var index = 0;
    if (subs.length == 0) {
        return (meds.map( medication => {
            index++;
            return (<div><MedicationIos medication={medication} showBranch={(medication.branch!="")} showClass={false} index={index} /></div>)
            }));
    } else {        
        // todo
        var isTitle = false;
        var medDivAR = [];

        for(var i=0;i<meds.length;i++) {
            let medication = meds[i];
            index++;
            if(medication.name.includes("<b>") || medication.name.includes("<i>")) {
                if(index % 2 == 0) {
                    index++;
                    medDivAR.push(<div></div>);
                }
                if(medication.name.includes("<b>")) {
                    medDivAR.push(<div style={{paddingTop:"10px"}}><h2 dangerouslySetInnerHTML={({__html: medication.name})}></h2></div>);
                } else {
                    medDivAR.push(<div><h3 dangerouslySetInnerHTML={({__html: medication.name})}></h3></div>);
                }
                isTitle = true;
            } else {
                if(isTitle && index % 2 == 0) {
                    index++;
                    medDivAR.push(<div></div>);
                    isTitle = false;
                }
                medDivAR.push(<div><MedicationIos medication={medication} showBranch={(medication.branch!="")} showClass={false} index={i} /></div>);
            }
        }
        return (medDivAR);
    }
}

function renderTherapies(ther) {
    ther = ther.map(m => {
        var therapy = therapies.find(mm => m.startsWith(mm.name) && m.includes("SOR"));

        if(therapy != null) {
            therapy = JSON.parse(JSON.stringify(therapy));
            therapy.name = m;
        } else {
            therapy = therapies.find(mm => m == mm.name);
        }
        return therapy;
    });
    
    var index = 0;
    return (ther.map( therapy => {
        index++;
        return (<div><TherapyIos therapy={therapy} index={"t" + index} /></div>)
    }));
    
}


const Page = ({ page, diagnoses, facts, setVariable, getVariable, goToNextPage, goToPrevPage, togglePDF, algorithm, setcalResult}) => {
    
    console.log(facts);
    if(facts != null) {
        if(Object.keys(facts).length != 0) {
            showFacts = true;
        } else {
            showFacts = false;
        }
    }

    let can_answer = (q) => {
        if (q.groups) {
            return true;
        }

        if (!q.choices) {
            return false;
        }

        let choice = q.choices.filter(c => c.enabled);
        return choice.length > 0;
    }

    
    let renderFigures = (figures) => {
        return (
            <ul>
                {figures.map(f => {
                    return (<li key={f}>
                        <button className='btnTitle' onClick={e => togglePDF((f.split("_")[0]))}><img src={"/assets/" + f + ".jpg"} /></button>
                    </li>);
                })}
            </ul>);
    }

    let needs_answer = (q) => {
        
        if(page.content.length > 0) {
            if(page.title == "STOP BANG" || page.title == "BMI (Adult)" || page.title == "CKD-Epi" || page.title == "COWS" || page.title == "Child-Pugh" || page.title == "Switching") {
                return true;
            }
        }

        if (can_answer(q) == false || q.type == "checkboxes" || q.id == "comorbidities") {
            return false;
        }

        if (q.type == "switch") {
            //let choice = q.groups.filter(g => needs_answer(g)); // choice always comes back as undefined
            if (q.groups.filter(g => needs_answer(g)).length > 0) {
                return true;
            }

            return false;
        }

        if (q.value != null  && (q.type == "choice" || q.type == "segment")) {
           // var choice = q.choices.filter(c => c.text === q.value || c.value === q.value); // choice always comes back as undefined
            return q.choices.filter(c => c.text === q.value || c.value === q.value).length == 0;
        }

        if (q.value && q.value.length > 0) {
            return false
        }

        if (q.type == "radio") {
            //let choice = q.groups.filter(g => needs_answer(g)); // choice always comes back as undefined
            return q.groups.filter(g => needs_answer(g)).length > 0;
        }

        if (q.type == "set")
        {
            return false;
        }
        
        return true;
    }

    let allQuestionsAnswered = (page) => {

        if (!page || !page.content) {
            return false;
        }

        let content = page.content.filter(c => c.type && needs_answer(c));
        return content.length === 0
    }

    function handleSubmit() {
        goToNextPage()

        var url = new URL(window.location.href);
        var cx = url.searchParams.get("context");
        if (cx) {
            navigate(`/app/?name=${diagnosis}&context=${cx}`, { state: {
                page: page
            }});
        } else {
            navigate(`/app/?name=${diagnosis}`, { state: {
                page: page
            }});
        }
    }

    function sortClicked(e) {
        let el = e.target;
        while (el && el !== e.currentTarget && el.tagName !== "SPAN") {
            el = el.parentNode;
        }
        if (el && el.tagName === "SPAN" && algorithm.ref_pdf != "") {
            togglePDF(algorithm.ref_pdf)
        }
    }
    
    function sectionContent() {
        calcIndex = 0;
        var splitContentHeader = [];
        var splitContent = [];
        var contentIndex = -1;
        if(page.state == "medication") {
            if(page.content != null) {
                for(var i=0;i<page.content.length;i++) {
                    // The medication titles and box about medication_history both have no id and are null If there is no box above medication_history, then i==0 just adds it
                    
                    if(page.content[i].id == null || i == 0) {
                        splitContent.push([page.content[i]]);
                        splitContentHeader.push(page.content[i])
                        contentIndex++;
                    } else {
                        splitContent[contentIndex].push(page.content[i]);
                    }
                }

                splitContentHeader = splitContent[0];
                splitContent.shift()
            }
            
            return(
                <div>
                    {
                    splitContentHeader.map((c) => mapContent(c, diagnoses, facts, setVariable, getVariable, page.state, sortClicked, algorithm, null))
                    }
                    {
                    splitContent.map((c) => <div><div>{mapContent(c[0], diagnoses, facts, setVariable, getVariable, page.state, sortClicked, algorithm, null)}</div><div className='grid-container'  style={{width:"102%", gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 49%))"}} >{c.map((d) => (c.indexOf(d) == 0) ? "" : <div>{mapContent(d, diagnoses, facts, setVariable, getVariable, page.state, sortClicked, algorithm, null)}</div>)}</div></div>)
                    }
                </div>
            )
        } else {
            return(
                (page.content || []).map((c) => mapContent(c, diagnoses, facts, setVariable, getVariable, page.state, page.variables["diagnosis"], sortClicked, algorithm, setcalResult))
            )
        }
    }

    let medication = page.state === "medication";
    let recommendation = page.state === "recommendation";
    let flow = !recommendation && allQuestionsAnswered(page);
    let isDiagnosis = (page.title == "Diagnosis") && allQuestionsAnswered(page);
    let variables = page.variables || {};

    let diagnosis = variables["diagnosis"];
    let branch = variables["medBranch"] || null;
    let population = variables["population"];
    let figures = page.figure || [];
    let navigate = useNavigate();
    
    return (
        <div id="guided_page">
            {!recommendation ? <div className="question-section">
                {sectionContent()}
                {(page.title == "Diagnosis" || page.title == "Switching" || page.type == "section" || !window.location.href.includes("?name") && !medication) ? "" : <BackFlow goToPrevPage={goToPrevPage} /> }
                {isDiagnosis ? <Flow goToNextPage={handleSubmit}></Flow> : flow ? <Flow goToNextPage={goToNextPage} /> : ""}
            </div> : 
            <div>
                {recommendation ? <BackFlow goToPrevPage={goToPrevPage} margin="0px 10px 0px 0px"/> : ""}
                <div className="question-section grid-container" style={{paddingTop:"30px"}}>
                    <div>
                        <h2>Recommendation</h2>
                        {sectionContent()}
                    </div>
                    <div>
                        <button className='btnTitle' onClick={e => togglePDF()} style={{height:"35px"}}><h2 style={{paddingLeft:"28px", height:"60px", color:"black", fontSize:"18pt", fontFamily:"futura-pt, Helvetica,arial,sans-serif"}}>Decision Support Tool</h2></button>
                        {setcalResult == null ? renderFigures(figures) : ""}
                    </div>
                </div>
            </div>}
            {recommendation && page.therapies && page.therapies.length > 0 ? (
                <div>
                    <h2 style={{paddingTop:"10px"}}>Therapy</h2>
                    <div style={{gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 48%))"}} className='grid-container'>
                        {renderTherapies(page.therapies)}
                    </div>
                </div>
            ) : null}
            {recommendation && page.medications && page.medications.length > 0 ? (
                <div>
                    <h2>Medications</h2>
                    <div style={{gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 48%))"}} className='grid-container'>
                        {renderMedications(page.medications, diagnosis, branch, population)}
                    </div>
                </div>
            ) : null}
        </div>
    )
};

export default Page